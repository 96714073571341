import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";

export interface FormData<T> {
    values: T;
    errors: { [k in keyof T]?: string };
    setValue: (key: keyof T) => (value: T[keyof T]) => void;
    setError: (key: keyof T) => (error: string) => void;
    setErrors: Dispatch<SetStateAction<{ [k in keyof T]?: string }>>;
    setValues: Dispatch<SetStateAction<T>>;
    reset: () => void;
    hasError: boolean;
}
export const useFormData = <T>(initialData: T): FormData<T> => {
    const initialDataRef = useRef<Readonly<T>>(initialData);
    const [values, setValues] = useState<T>({
        ...(initialDataRef.current || ({} as any)),
    });
    const [errors, setErrors] = useState<{ [k in keyof T]?: string }>({});
    const setValueCallbacksRef = useRef<
        Map<keyof T, (value: T[keyof T]) => void>
    >(new Map());
    const setErrorCallbacksRef = useRef<Map<keyof T, (error: string) => void>>(
        new Map(),
    );
    const setValue = useCallback((key: keyof T) => {
        let callback: (value: T[keyof T]) => void =
            setValueCallbacksRef.current.get(key);
        if (callback) return callback;
        callback = (value: T[keyof T]) => {
            setValues((prev) => {
                if (prev[key] === value) return prev;
                return { ...prev, [key]: value };
            });
        };
        setValueCallbacksRef.current.set(key, callback);
        return callback;
    }, []);
    const setError = useCallback((key: keyof T) => {
        let callback: (error: string) => void =
            setErrorCallbacksRef.current.get(key);
        if (callback) return callback;
        callback = (error: string) =>
            setErrors((prev) => {
                if ((prev[key] || "") === error) return prev;
                return { ...prev, [key]: error };
            });
        setErrorCallbacksRef.current.set(key, callback);
        return callback;
    }, []);

    const reset = useCallback(() => {
        setErrors({});
        setValues({ ...initialDataRef.current });
    }, []);
    return {
        values,
        errors,
        setValue,
        setError,
        setErrors,
        setValues,
        hasError: Object.values(errors).some((v) => v),
        reset,
    };
};
