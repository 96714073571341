import { createContext, useContext } from "react";

export interface PageBreadcrumb {
    name: string;
    link: string;
}
export interface PageContextProps {
    isMenuOpened: boolean;
    setFilters: (node: React.ReactNode) => void;
    showFilters: () => void;
    hideFilters: () => void;
    toggleFilters: () => void;
    setBreadcrumbs: (items: PageBreadcrumb[]) => void;
}
const value: PageContextProps = {
    isMenuOpened: false,
    setFilters: () => null,
    showFilters: () => null,
    hideFilters: () => null,
    toggleFilters: () => null,
    setBreadcrumbs: () => null,
};
export const PageContext = createContext<PageContextProps>(value);
PageContext.displayName = "PageContext";

export const usePageContext = () => {
    const page = useContext(PageContext);
    return page;
};
export default PageContext;
