import {
    FormControl,
    InputLabel,
    Input as MuiInput,
    FormHelperText,
    InputAdornment,
} from "@mui/material";
import { isEqual } from "lodash";
import {
    ChangeEvent,
    ComponentProps,
    memo,
    useCallback,
    useEffect,
    useState,
} from "react";
import useTranslationMessages from "src/translations/useMessages";
import { Validator } from "src/utils/validators/types";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type MuiInputProps = ComponentProps<typeof MuiInput>;
export interface FormPasswordInputProps {
    value: string;
    onChange: (value: string) => void;
    onValidationError?: (message: string) => void;
    label?: string;
    inputProps?: MuiInputProps["inputProps"];
    required?: boolean;
    error?: string;
    validators?: Validator[];
    multiline?: boolean;
    rows?: number;
}

export const FormPasswordInput = memo(
    (props: FormPasswordInputProps) => {
        const {
            value,
            error,
            onChange,
            onValidationError,
            label,
            inputProps = {},
            required,
            validators,
            multiline,
            rows,
        } = props;
        const t = useTranslationMessages();
        const [showPassword, setShowPassword] = useState(false);
        const toggleShowPassword = useCallback(() => {
            setShowPassword((prev) => !prev);
        }, []);
        const mergedInputProps = { maxLength: 200, ...inputProps };
        const onInputChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                const v = e.target.value;
                onChange(v);
            },
            [onChange],
        );

        useEffect(() => {
            let msg = "";
            let validate = false;
            if (required || (value !== "" && validators)) validate = true;
            if (validate) {
                if (validators?.length) {
                    for (let validator of validators) {
                        if (!validator.validate(value)) {
                            msg = validator.message(t);
                            break;
                        }
                    }
                }
                if (!msg && required) msg = value ? "" : t.required;
            }
            if (error === msg) return;
            if (onValidationError) onValidationError(msg);
        }, [value, validators, required, onValidationError, t, error]);

        return (
            <FormControl
                required={required}
                fullWidth
                error={!!error}
                variant="standard"
                margin="dense">
                <InputLabel>{label}</InputLabel>
                <MuiInput
                    type={showPassword ? "text" : "password"}
                    inputProps={mergedInputProps}
                    value={value}
                    onChange={onInputChange}
                    multiline={multiline}
                    rows={rows}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onClick={toggleShowPassword} edge="end">
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        );
    },
    (pp, p) => isEqual(pp, p),
);

export default FormPasswordInput;
