import React, {useEffect, useState} from "react";
import {Box, Button, Stack, Theme, useMediaQuery} from "@mui/material";
import FormCheckbox from "../../../../../components/Form/Checkbox";
import FormInput from "../../../../../components/Form/Input";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useTranslationMessages from "../../../../../translations/useMessages";
import {Close} from "@mui/icons-material";
import {ObjectiveAnswer} from "../../../../../redux/entities/ObjectiveQuestionnaire/entity";
import {ObjectiveOptionDto} from "../../../../../reduxV2/entities/ObjectiveQuestionnaire/codegen.api";

interface AnswersProps {
    answers: ObjectiveOptionDto[];
    currentLang: any;
    onAnswersUpdated: (answers: ObjectiveOptionDto[]) => void;
    languages: any;
}

const Answers = (props: AnswersProps) => {
    const {answers, currentLang, onAnswersUpdated, languages} = props;
    const t = useTranslationMessages();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm"),
    );

    function getFormValue(arrValues: any, field: any) {
        if (arrValues.find((el) => el.language === currentLang.value)) {
            return arrValues.find((el) => el.language === currentLang.value)[field]
        } else {
            const copyDef = [...answers]
            onAnswersUpdated(copyDef.map((ans) => ({
                ...ans,
                objectiveOptionDefinitions: [...ans.objectiveOptionDefinitions, {
                    id: 0,
                    description: "",
                    language: currentLang.value
                }],
            })));
            return '';
        }
    }

    return (
        <Stack
            width={"100%"}
            direction={isMobile ? "column" : "row"}
            flexDirection={isMobile ? "column-reverse" : undefined}
            justifyContent="space-between"
            flexWrap={"wrap"}
            alignItems="center"
            spacing={isMobile ? 0 : 4}
            marginBottom={isMobile ? 0 : 4}>
            {answers.map((el, idx) => (
                <Box key={idx} sx={isMobile ? {width: 1, mt: 4} : {flex: 1, position: 'relative'}}>
                    <FormInput
                        required
                        label={t.answer}
                        error={!getFormValue(el.objectiveOptionDefinitions, 'description') && t.required}
                        value={getFormValue(el.objectiveOptionDefinitions, 'description')}
                        onChange={(val) => {
                            const copyAnswers = [...answers];
                            copyAnswers[idx].objectiveOptionDefinitions.find((ans) => ans.language === currentLang.value).description = val;
                            onAnswersUpdated(copyAnswers)
                        }}
                    />
                    <FormInput
                        type="number"
                        required
                        error={!el.score && t.required}
                        label={t.score}
                        value={String(el.score)}
                        onChange={(val) => {
                            const copyAnswers = [...answers];
                            copyAnswers[idx].score = Number(val);
                            onAnswersUpdated(copyAnswers)
                        }}
                    />
                    {answers.length > 2 && <Close sx={{position: 'absolute', cursor: 'pointer'}}
                                                  onClick={() => onAnswersUpdated(answers.filter((ans, i) => i !== idx))}/>}
                </Box>
            ))}
            <Button
                startIcon={<AddCircleOutlineIcon/>}
                variant="contained"
                disabled={answers.length > 7}
                onClick={() => {
                    const newAnswer = {
                        objectiveOptionId: 0,
                        id: 0,
                        index: 0,
                        objectiveQuestionId: 0,
                        score: 0,
                        objectiveOptionDefinitions: languages.map((lang) => {
                            return {
                                id: 0,
                                description: "",
                                language: lang.value
                            }
                        })
                    }
                    onAnswersUpdated([...answers, newAnswer])
                }}>
                {t.addAnswer}
            </Button>
        </Stack>
    );
};

export default Answers;