import {ReactNode, useState, useCallback, useEffect} from "react";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {useMediaQuery} from "@mui/material";
import {useIsUserLoggedIn} from "src/components/AuthProvider/store";
import PageContext, {PageBreadcrumb} from "./context";
import PageAppBar from "./appbar";
import * as styles from "./styles";
import MenuDrawer from "./menu";
import FiltersDrawer from "./filters";
import {useLocation} from "react-router-dom";
import {GAPageView} from "src/services/tracking/ga";
import PageRoleChecker from "../AuthProvider/PageRoleChecker";
import {Provider as RollbarProvider, ErrorBoundary} from "@rollbar/react";
import ErrorBoundaryFallback from "./ErrorBoundaryFallback";

const config = {
    accessToken: process.env["REACT_APP_ROLLBAR_ACCESS_TOKEN"] || "",
    environment: process.env["REACT_APP_ENVIRONMENT"] || "dev",
};

export interface PageProps {
    children: ReactNode;
}

export const Page = (props: PageProps): any => {
    const theme = useTheme();
    const location = useLocation();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    const isLoggedIn = useIsUserLoggedIn();
    const [open, setOpen] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [filters, setFilters] = useState<ReactNode>(null);
    const [breadcrumbs, setBreadcrumbs] = useState<PageBreadcrumb[]>([]);

    const showFilters = useCallback(() => setIsFiltersOpen(true), []);
    const hideFilters = useCallback(() => setIsFiltersOpen(false), []);
    const toggleFilters = useCallback(
        () => setIsFiltersOpen(!isFiltersOpen),
        [isFiltersOpen],
    );
    const handleDrawerOpen = useCallback(() => setOpen(true), []);
    const handleDrawerClose = useCallback(() => setOpen(false), []);

    const mainStyles = [
        styles.main(useMediaQuery(theme.breakpoints.down("sm"))),
    ];
    if (!isMobile) {
        mainStyles.push(
            styles.mainDesktop,
            open && styles.mainOpened,
            isFiltersOpen && styles.mainFiltersOpened,
        );
    }

    const onNavigate = useCallback(() => {
        if (isMobile) setOpen(false);
        setIsFiltersOpen(false);
    }, [isMobile]);

    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            return;
            GAPageView();
        }
    }, [location]);

    if (!isLoggedIn) return props.children;
    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100vh"
            width="100vw"
            id="root-container">
            <CssBaseline/>
            <PageAppBar
                isMenuOpen={open}
                isFiltersOpen={isFiltersOpen}
                breadcrumbs={breadcrumbs}
                isMobile={isMobile}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
            />
            <MenuDrawer
                isMobile={isMobile}
                open={open}
                onNavigate={onNavigate}
                handleDrawerClose={handleDrawerClose}
            />
            <PageContext.Provider
                value={{
                    isMenuOpened: open,
                    setFilters,
                    showFilters,
                    hideFilters,
                    toggleFilters,
                    setBreadcrumbs,
                }}>
                <Box component="main" sx={mainStyles}>
                    <RollbarProvider config={config}>
                        <ErrorBoundary fallbackUI={ErrorBoundaryFallback}>
                            <PageRoleChecker>{props.children}</PageRoleChecker>
                        </ErrorBoundary>
                    </RollbarProvider>
                </Box>
            </PageContext.Provider>
            <FiltersDrawer
                isMobile={isMobile}
                isFiltersOpen={isFiltersOpen}
                hideFilters={hideFilters}
                filters={filters}
            />
        </Box>
    );
};

export default Page;
