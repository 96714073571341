import TranslationMessages from "src/translations/messages";
import {SendSubjectiveQuestionnaireToClientApiResponse} from "../reduxV2/entities/SubjectiveQuestionnaire";
import {Transactions} from "../pages/Transactions";

export interface RouteProps {
    name: keyof TranslationMessages;
    path: string;
    getRoute?: (...args: any) => string;
}

export const routes = {
    root: {
        name: "home",
        path: "/",
    } as RouteProps,
    homepage: {
        name: "home",
        path: "/home",
    },
    summary: {
        name: "summary",
        path: "/summary",
    },
    privacyPolicy: {
        name: "privacyPolicy",
        path: "/privacy-policy",
    },
    termsOfService: {
        name: "termsOfService",
        path: "/terms-of-service",
    },
    login: {
        name: "login",
        path: "/login",
    } as RouteProps,
    acceptInvite: {
        name: "acceptInvite",
        path: "/accept-invite",
    },
    signUp: {
        name: "signUp",
        path: "/signup",
    } as RouteProps,
    forgotPassword: {
        name: "forgotPassword",
        path: "/forgot-password",
    } as RouteProps,
    resetPassword: {
        name: "resetPassword",
        path: "/reset-password",
    } as RouteProps,
    confirmEmail: {
        name: "confirmEmail",
        path: "/confirm-email",
    },
    clients: {
        name: "clients",
        path: "/clients",
    } as RouteProps,
    client: {
        name: "client",
        path: "/clients/:id/*",
        getRoute: (id: number) => `/clients/${id}`,
    } as RouteProps,
    editClient: {
        name: "edit",
        path: "/clients/:id/edit",
        getRoute: (id: number) => `/clients/${id}/edit`,
    },
    sessions: {
        name: "sessions",
        path: "/clients/:id/sessions",
        getRoute: (clientId: number) => `/clients/${clientId}/sessions`,
    },
    session: {
        name: "session",
        path: "/session/:id",
        getRoute: (sessionId: number) => `/session/${sessionId}`,
    },
    insights: {
        name: "insights",
        path: "/clients/:id/insights",
        getRoute: (clientId: number) => `/clients/${clientId}/insights`,
    },
    balance: {
        name: "balance",
        path: "/clients/:id/balance",
        getRoute: (clientId: number) => `/clients/${clientId}/balance`,
    },
    settings: {
        name: "settings",
        path: "/settings",
    },
    calendar: {
        name: "calendar",
        path: "/calendar",
    },
    questionnaires: {
        name: "questionnaires",
        path: "/questionnaires",
    },
    clientObjectiveQuestionnaire: {
        name: "clientObjectiveQuestionnaire",
        path: "/objective-questionnaire/:id/:lang/:clientQuestionnaireId/:clientId/:token",
        getRoute: (id: string, lang: string, clientQuestionnaireId: string, clientId: string, token: string) => `/objective-questionnaire/${id}/${lang}/${clientQuestionnaireId}/${clientId}/${token}`,
    },
    clientSubjectiveQuestionnaire: {
        name: "clientSubjectiveQuestionnaire",
        path: "/subjective-questionnaire/:id/:clientQuestionnaireId/:clientId/:token",
        getRoute: (id: string, clientQuestionnaireId: string, clientId: string, token: string) => `/subjective-questionnaire/${id}/${clientQuestionnaireId}/${clientId}/${token}`,
    },
    questionnaire: {
        name: "questionnaire",
        path: "/questionnaire/:id/:lang/*",
        getRoute: (id: string) => `/questionnaire/${id}`,
    } as RouteProps,
    newObjectiveQuestionnaire: {
        name: "questionnaires",
        path: "/questionnaires/new-objective-questionnaire",
    },
    editObjectiveQuestionnaire: {
        name: "questionnaires",
        path: "/questionnaires/:id/edit",
        getRoute: (id: string) => `/questionnaires/${id}/edit`,
    },
    printClient: {
        name: "print",
        path: "/print-client/:clientId",
        getRoute: (clientId: number) => `/print-client/${clientId}`,
    },
    users: {
        name: "users",
        path: "/users",
    },
    transactions: {
        name: "transactions",
        path: "/transactions",
    }
};

export default routes;
