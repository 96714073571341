import { Suspense, lazy } from "react";
import CenteredSpinner from "src/components/CenteredSpinner";
import RequireAuth from "./getProtectedRoute";

export const getSuspendedProtectedRoute =
    <ComponentProps,>(loader: () => any) =>
    (props: ComponentProps) => {
        const C = lazy(loader);
        return (
            <RequireAuth>
                <Suspense fallback={<CenteredSpinner />}>
                    <C {...props} />
                </Suspense>
            </RequireAuth>
        );
    };

export default getSuspendedProtectedRoute;
