import {Fragment, memo} from "react";
import {Theme} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {Link, Stack, useMediaQuery} from "@mui/material";
import {PageBreadcrumb} from "./context";

import * as styles from "./styles";
import User from "./user";
import useIsMobile from "src/hooks/breakpoints/useMobile";

export interface PageAppBarProps {
    isMenuOpen: boolean;
    isFiltersOpen: boolean;
    breadcrumbs: PageBreadcrumb[];
    isMobile: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
}

export const PageAppBar = memo((props: PageAppBarProps) => {
    const {isMenuOpen, isFiltersOpen, breadcrumbs, handleDrawerOpen} = props;
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm"),
    );
    const isTinyMobile = useIsMobile();
    return (
        <MuiAppBar
            position="relative"
            id="app-bar"
            sx={[
                styles.appBar,
                isMenuOpen && styles.appBarOpened,
                isFiltersOpen && styles.appBarFiltersOpened,
            ]}>
            <Toolbar sx={{display: "flex", flexDirection: "row", width: 1}}>
                <Stack
                    width={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <Stack
                        direction="row"
                        alignItems="center"
                        flex={1}
                        overflow="hidden">
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                mr: 2,
                                color: "black",
                                backgroundColor: 'transparent!important',
                                ...(isMenuOpen && {display: "none"}),
                            }}>
                            <MenuIcon/>
                        </IconButton>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            color="primary">
                            {!isMenuOpen && (
                                <>
                                    <Link href="/" color="primary">
                                        Measurme
                                    </Link>
                                    {isTinyMobile
                                        ? null
                                        : (isMobile
                                                ? breadcrumbs.slice(-1)
                                                : breadcrumbs || []
                                        ).map((b) => (
                                            <Fragment key={b.link}>
                                                {" / "}
                                                <Link
                                                    href={b.link}
                                                    key={b.link}>
                                                    {b.name}
                                                </Link>
                                            </Fragment>
                                        ))}
                                </>
                            )}
                            {(isMenuOpen &&
                                    (breadcrumbs.slice(-1) || [])?.map((b) => (
                                        <Link href={b.link} key={b.link}>
                                            {b.name}
                                        </Link>
                                    ))) ||
                                null}
                        </Typography>
                    </Stack>
                    <User/>
                </Stack>
            </Toolbar>
        </MuiAppBar>
    );
});

export default PageAppBar;
