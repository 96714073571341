import { css } from "@emotion/react";

export const styles = css({
    html: {
        width: "100vw",
        height: "100vh",
        margin: 0,
        padding: 0,
    },
    body: {
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100%",
        background: "#fafafa",
    },

    "#root": {
        width: "100%",
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
    },
    div: {
        boxSizing: "border-box",
        display: "block",
    },
});

export default styles;
