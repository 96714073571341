import {createTheme, responsiveFontSizes} from "@mui/material/styles";
import palette from "./colors";
import LinkBehavior from "./link";

const spacing = 8;

const defaultTheme = createTheme();
let baseTheme = createTheme({
    palette: {
        primary: {
            main: palette.primary[500],
            light: palette.primary[300],
            dark: palette.primary[600],
            contrastText: palette.shades[0],
        },
        secondary: {
            main: palette.secondary[500],
            light: palette.secondary[300],
            dark: palette.secondary[600],
        },
        error: {
            main: palette.error[500],
            light: palette.error[300],
            dark: palette.error[600],
        },
        warning: {
            main: palette.warning[500],
            light: palette.warning[300],
            dark: palette.warning[600],
            contrastText: palette.shades[0],
        },
        success: {
            main: palette.success[500],
            light: palette.success[300],
            dark: palette.success[600],
            contrastText: palette.shades[0],
        },
        info: {
            main: palette.accentPrimary[500],
            light: palette.accentPrimary[300],
            dark: palette.accentPrimary[600],
            contrastText: palette.shades[0],
        },
        background: {
            default: palette.neutral[50],
            paper: palette.shades[0],
        },
        text: {
            primary: palette.neutral[800],
            secondary: palette.neutral[500],
            disabled: palette.neutral[300],
        },
    },
    spacing,
    shape: {
        borderRadius: spacing / 2,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    borderRadius: '25px',
                    boxShadow: "none",
                    "&:hover": {
                        borderRadius: '25px',
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
                    },
                },
                colorInherit: {
                    borderRadius: '25px',
                    color: palette.neutral[600],
                },
                containedInherit: {
                    borderRadius: '25px',
                    background: palette.neutral[50],
                    "&:hover": {
                        background: palette.neutral[100],
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            } as any,
        },
        MuiSnackbar: {
            styleOverrides: {
                anchorOriginTopRight: {
                    top: 80,
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    [defaultTheme.breakpoints.down(460)]: {
                        flexDirection: "column-reverse",
                        "&>:not(:last-of-type)": {
                            marginLeft: 0,
                            marginTop: 8,
                        },
                    },
                    padding: "8px 24px 16px",
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: 24,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: palette.neutral[100],
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    width: 600,
                },
                paperFullScreen: {
                    width: "100vw",
                    "& .MuiDialogTitle-root": {
                        padding: `${defaultTheme.spacing(
                            2,
                        )} ${defaultTheme.spacing(3)}`,
                        minHeight: 64,
                        background: palette.neutral[100],
                        boxShadow: defaultTheme.shadows[4],
                    },
                },
                paperFullWidth: {
                    width: "100%",
                },
            },
        },
    },
});

export const theme = responsiveFontSizes(baseTheme);

export default theme;
