import {LoginResponse, StringInt32KeyValuePair} from "src/reduxV2/entities/Account";
import createStore from "src/utils/store";

export const LOCAL_STORAGE_SESSION_KEY = "measurme_session";
export const LOCAL_STORAGE_USER_ID_KEY = "measurme_user_id";

export enum UserRoles {
    none = "NONE",
    administrator = "ADMIN",
    therapist = "THERAPIST",
    director = "DIRECTOR",
    supervisor = "SUPERVISOR",
    secretary = "SECRETARY"
}

export enum TenantTypes {
    psychologyCenter = "PsychologyCenter",
    invidual = "Invidual",
}

export enum UserStatusEnum {
    invited = "Invited",
    joined = "Joined",
}

const logout = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY);
    AccountStore.setState({
        session: null,
        isLoggedIn: false,
        isLoading: false,
        userId: null,
        role: UserRoles.none,
        logout: logout,
    });
};

export interface Session {
    id: null | number;
    userRolesWithId?: StringInt32KeyValuePair[] | null;
    accessToken?: string | null;
    accessTokenExpTime?: string;
    refreshToken?: string | null;
    type?: TenantTypes;
}

export interface AccountStoreProps {
    session: null | Session;
    isLoggedIn: boolean;
    isLoading: boolean;
    userId?: null | number;
    role: UserRoles;
    logout: () => void;
}

export const AccountStore = createStore<AccountStoreProps>({
    session: null,
    isLoggedIn: false,
    isLoading: true,
    userId: null,
    role: UserRoles.none,
    logout,
});

export const useAccount = AccountStore.useStore;

const selectors = {
    isLoggedIn: (s: AccountStoreProps) => s.isLoggedIn,
    isLoading: (s: AccountStoreProps) => s.isLoading,
    session: (s: AccountStoreProps) => s.session,
    userId: (s: AccountStoreProps) => s.userId,
    role: (s: AccountStoreProps) => s.role,
    tenantId: (s: AccountStoreProps) => s.session.id,
    logout: (s: AccountStoreProps) => s.logout,
};

export const useIsUserLoggedIn = () => useAccount(selectors.isLoggedIn);
export const useAuthLoading = () => useAccount(selectors.isLoading);
export const useSession = () => useAccount(selectors.session);
export const useUserId = () => useAccount(selectors.userId);
export const useUserRole = () => useAccount(selectors.role);
export const useTenantId = () => useAccount(selectors.tenantId);
export const useLogout = () => useAccount(selectors.logout);

export default AccountStore;
