import { Box, Button, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { memo, useEffect } from "react";
import FormRadioGroup from "src/components/Form/RadioGroup";
import { useFormData } from "src/components/Form/useFormData";
import Spinner from "src/components/Spinner";
import useTranslationMessages from "src/translations/useMessages";
import { useParams } from "react-router-dom";
import ObjectiveQuestionnaireApi from "../../../../reduxV2/entities/ObjectiveQuestionnaire";
import BeenhereIcon from "@mui/icons-material/Beenhere";

export interface QuestionnaireViewProps {
    //data: Questionnaire;
    data: any;
}

export const QuestionnaireView = memo((props: QuestionnaireViewProps) => {
    const { data: d } = props;
    const { enqueueSnackbar } = useSnackbar();
    const t = useTranslationMessages();
    const { lang, clientId, token, clientQuestionnaireId } = useParams();
    const { objectiveQuestionnaireQuestions } = d;
    const [save, { isLoading: isSaving, isSuccess, isError }] =
        ObjectiveQuestionnaireApi.useSentAnswersForClientQuestionnaireMutation();

    function getOptions(question) {
        return question.objectiveQuestionnaireOptions.map((option) => ({
            key: option.id,
            label: getFilterArrayByLanguage(option.objectiveOptionDefinitions).description,
        }));
    }

    function getFilterArrayByLanguage(arr) {
        const result = arr.filter((el) => el.language === lang);
        return result[0]
    }
    const formData = useFormData({
        clientQuestionnaireId: +clientQuestionnaireId,
        answers: []
    });

    const onSave = () => {
        save({
            setAnswersForClientQuestionnaire: formData.values,
            headers: {
                "X-Api-Key": token,
                "Client-Id": clientId,
            }
        })
    };
    
    useEffect(() => {
        if(isError) {
            enqueueSnackbar(t.smthWentWrongPlsContactSupport, {
                variant: "error",
            });
        }
    }, [isError]);


    if (isSuccess)
        return (
            <Box p={4} textAlign="center">
                <BeenhereIcon
                    color="success"
                    sx={{ width: 150, height: 150, mb: 5 }}
                />
                <Typography variant="h6" component="div">
                    {t.youHaveAnsweredForThisQuestionnaire}
                </Typography>
            </Box>
        );

    return (
        <Box sx={{margin: clientId && 3}}>
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h4">{getFilterArrayByLanguage(d.objectiveQuestionnaireDefinitions).title}</Typography>
                    <Typography variant="body1">{getFilterArrayByLanguage(d.objectiveQuestionnaireDefinitions).description}</Typography>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography variant="h6" mb={1}>
                        {d.generalText}
                    </Typography>
                    <Divider sx={{ mb: 4 }} />
                    {objectiveQuestionnaireQuestions.map((q, i) => (
                        <FormRadioGroup
                            label={`${i + 1}. ${getFilterArrayByLanguage(q.objectiveQuestionnaireQuestionDefinitions).description}`}
                            key={q.id}
                            options={getOptions(q)}
                            required={!!clientId}
                            value={formData.values.answers[i]}
                            error={formData.errors[i]}
                            onChange={(val) => {
                                const updatedAnswers = [...formData.values.answers];
                                updatedAnswers[i] = {
                                    question: q.objectiveQuestionnaireQuestionDefinitions[0].description,
                                    answer: q.objectiveQuestionnaireOptions.filter((option) => option.id === +val)[0].objectiveOptionDefinitions[0].description,
                                    score: q.objectiveQuestionnaireOptions.filter((option) => option.id === +val)[0].score,
                                };
                                formData.setValue("answers")(updatedAnswers);
                            }}
                            onValidationError={formData.setError(i)}
                        />
                    ))}
                    {clientId && (
                        <Stack direction="row" justifyContent="center">
                            {isSaving ? (
                                <Spinner />
                            ) : isSuccess ? null : (
                                <Button
                                    variant="contained"
                                    disabled={formData.hasError}
                                    onClick={onSave}
                                    >
                                    {t.save}
                                </Button>
                            )}
                        </Stack>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
});

export default QuestionnaireView;
