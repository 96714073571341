import { createApi } from "@reduxjs/toolkit/query/react";
import getBaseQuery from "../getBaseQuery";
import {
    AddQuestionnaireResultInput,
    ClientQuestionnaire,
    ClientQuestionnaireAnswersInput,
    ClientQuestionnairesResult,
    GetClientQuestionnairesResultsInput,
    Questionnaire,
    SendQuestionnaireInput,
} from "./entity";
import { LanguageStore } from "src/translations/store";

const getLanguage = () => {
    const l = LanguageStore.getState().language;
    if (l === "uk") return "ua";
    return l;
};

const tag = "Questionnaire";
const clientTag = "ClientQuestionnaire";
const resTag = "ClientQuestionnaireRes";
const getListId = (language: string) => `list_${tag}_${language}`;
const clientListId = (clientId: string) => `list_${tag}_${clientId}`;

const QuestionnaireApi = createApi({
    reducerPath: tag,
    baseQuery: getBaseQuery(),
    tagTypes: [tag, clientTag, resTag],
    endpoints: (build) => ({
        getQuestionnaireDefinition: build.query<Questionnaire, string>({
            query: (id) => ({ url: `/questionnaire-definition/${id}` }),
            transformResponse: (response) => response as Questionnaire,
            providesTags: (result, error, id) => [{ type: tag, id }],
        }),
        getClientQuestionnaire: build.query<ClientQuestionnaire, string>({
            query: (id) => ({ url: `/questionnaire/${id}` }),
            transformResponse: (response) => response as ClientQuestionnaire,
            providesTags: (result, error, id) => [{ type: clientTag, id }],
        }),
        listClientQuestionnaires: build.query<ClientQuestionnaire[], string>({
            query: (clientId) => ({
                url: `/questionnaire?clientId=${clientId}&size=1000`,
            }),
            transformResponse: (response) =>
                response && Array.isArray(response) ? [...response] : [],
            providesTags: (result, error, clientId) => [
                { type: clientTag, id: clientListId(clientId) },
            ],
        }),
        listQuestionnaires: build.query<Questionnaire[], void>({
            query: () => {
                return {
                    url: `/questionnaire-definition?lang=${getLanguage()}`,
                };
            },
            transformResponse: (response) =>
                response && Array.isArray(response) ? [...response] : [],
            providesTags: (result, error, input) =>
                result && Array.isArray(result)
                    ? [
                          ...result.map(
                              ({ id }) => ({ type: tag, id } as const),
                          ),
                          { type: tag, id: getListId(getLanguage()) },
                      ]
                    : [{ type: tag, id: getListId(getLanguage()) }],
        }),
        send: build.mutation<void, SendQuestionnaireInput>({
            query: (input) => ({
                url: "/questionnaire-send",
                method: "POST",
                body: input,
            }),
        }),
        addResult: build.mutation<void, AddQuestionnaireResultInput>({
            query: (input) => ({
                url: "/questionnaire",
                method: "POST",
                body: input,
            }),
        }),
        clientAnswer: build.mutation<void, ClientQuestionnaireAnswersInput>({
            query: (input) => {
                const { id, ...body } = input;
                return {
                    url: `/questionnaire/${id}`,
                    method: "POST",
                    body,
                };
            },
        }),
        getQuestionnairesChartsData: build.query<
            ClientQuestionnairesResult[],
            GetClientQuestionnairesResultsInput
        >({
            query: ({ clientId, questionnaireId }) =>
                `/clients/${clientId}/questionnaire-charts/${questionnaireId}`,
            transformResponse: (response: any) =>
                [...(response?.data || [])] as ClientQuestionnairesResult[],
            providesTags: (result, error, input) =>
                result && Array.isArray(result)
                    ? [
                          ...result.map(
                              (res, i) =>
                                  ({
                                      type: resTag,
                                      id: `${input.clientId}_${input.questionnaireId}_${i}`,
                                  } as const),
                          ),
                          {
                              type: resTag,
                              id: `list_${resTag}_${input.clientId}_${input.questionnaireId}`,
                          },
                      ]
                    : [
                          {
                              type: resTag,
                              id: `list_${resTag}_${input.clientId}_${input.questionnaireId}`,
                          },
                      ],
        }),
    }),
});

export default QuestionnaireApi;
