import { Suspense, lazy } from "react";
import CenteredSpinner from "src/components/CenteredSpinner";

export const getSuspendedElement =
    <ComponentProps,>(loader: () => any) =>
    (props: ComponentProps) => {
        const C = lazy(loader);
        return (
            <Suspense fallback={<CenteredSpinner />}>
                <C {...props} />
            </Suspense>
        );
    };

export default getSuspendedElement;
