import {
    FormControl,
    FormHelperText,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { isEqual } from "lodash";
import { memo, useCallback, useEffect } from "react";
import useTranslationMessages from "src/translations/useMessages";

export interface FormCheckboxProps {
    value: boolean;
    onChange: (value: boolean) => void;
    onValidationError?: (message: string) => void;
    label?: string;
    required?: boolean;
    error?: string;
    fullWidth?: boolean;
}

export const FormCheckbox = memo(
    (props: FormCheckboxProps) => {
        const {
            value,
            error,
            onChange,
            onValidationError,
            label,
            required,
            fullWidth = true,
        } = props;
        const t = useTranslationMessages();
        const onCheckBoxChange = useCallback(
            (_: unknown, v: boolean) => onChange(v),
            [onChange],
        );

        useEffect(() => {
            let msg = "";
            if (required) msg = value ? "" : t.required;
            if (error === msg) return;
            if (onValidationError) onValidationError(msg);
        }, [value, required, onValidationError, t, error]);

        return (
            <FormControl
                required={required}
                fullWidth={fullWidth}
                error={!!error}
                variant="standard"
                margin="dense">
                <FormControlLabel
                    control={
                        <Checkbox checked={value} onChange={onCheckBoxChange} />
                    }
                    label={label}
                />
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        );
    },
    (pp, p) => isEqual(pp, p),
);

export default FormCheckbox;
