/** @jsxImportSource @emotion/react */
import { Box, BoxProps } from "@mui/material";
import { ComponentProps } from "react";
import Spinner from "src/components/Spinner";
import styles from "./styles";

export interface CenteredSpinnerProps {
    sx?: BoxProps["sx"];
    spinnerProps?: ComponentProps<typeof Spinner>;
}
export const CenteredSpinner = (props: CenteredSpinnerProps) => (
    <Box css={styles.container} sx={props.sx}>
        <Spinner {...props.spinnerProps} />
    </Box>
);

CenteredSpinner.displayName = "CenteredSpinner";
export default CenteredSpinner;
