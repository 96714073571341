import { useEffect } from "react";
import { useFormatMessage } from "./useMessages";
import { LanguageStore } from "./store";

export const FormatMessageUpdater = () => {
    const formatMessage = useFormatMessage();
    useEffect(() => {
        LanguageStore.setValue("formatMessage", formatMessage);
    }, [formatMessage]);

    return null;
};

export default FormatMessageUpdater;
