import {
    useAuthLoading,
    useIsUserLoggedIn,
} from "src/components/AuthProvider/store";
import CenteredSpinner from "src/components/CenteredSpinner";
import AuthRouter from "./AuthRouter";
import UnAuthRouter from "./UnAuthRouter";

export const Router = () => {
    const isLoading = useAuthLoading();
    const isLoggedIn = useIsUserLoggedIn();
    if (isLoading) return <CenteredSpinner />;
    return isLoggedIn ? <AuthRouter /> : <UnAuthRouter />;
};

export default Router;
