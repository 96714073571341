import {InjectedPsychologyCenterApi} from "./codegen.api";

export * from "./codegen.api";

const type = "PsychologyCenter";
const psychologyCenterList = "PsychologyCenterList";

export const PsychologyCenterApi = InjectedPsychologyCenterApi.enhanceEndpoints({
    addTagTypes: [type, psychologyCenterList],
    endpoints: {
        AcceptInvite: {
            invalidatesTags: (respose, error, args) => [
                {type: psychologyCenterList},
            ],
        },
        SendInvite: {
            invalidatesTags: (respose, error, args) => [
                {type: psychologyCenterList},
            ],
        },
        LockUser: {
            invalidatesTags: (respose, error, args) => [
                {type: psychologyCenterList},
            ],
        },
        UnLockUser: {
            invalidatesTags: (respose, error, args) => [
                {type: psychologyCenterList},
            ],
        },
        GetUsers: {
            providesTags: (respose, error, args) => [
                {type: psychologyCenterList},
            ],
        },
        GetTherapistsNames: {
            providesTags: (respose, error, args) => [
                {type: psychologyCenterList},
            ],
        }
    },
});
export default PsychologyCenterApi;
