import theme from "src/theme";
import {SxStyle} from "src/theme/styles";

export const drawerWidth = 240;

export const main: (isMobile: boolean) => SxStyle = (isMobile) => ({
    flex: 1,
    padding: theme.spacing(isMobile ? 1 : 3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "auto",
});
export const mainDesktop: SxStyle = {
    marginLeft: 0,
    marginRight: 0,
};
export const mainOpened: SxStyle = {
    transition: theme.transitions.create("marginLeft", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
};
export const mainFiltersOpened: SxStyle = {
    transition: theme.transitions.create("marginRight", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: `${drawerWidth}px`,
};
export const appBar: SxStyle = {
    height: '64px',
    display: "flex",
    flexDirection: "row",
    background: "#fff",
    color: "#000",
    zIndex: 10,
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.05), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)',
    "@media print": {
        display: "none",
    },
};
export const appBarOpened: SxStyle = {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
};
export const appBarFiltersOpened: SxStyle = {};
export const drawerHeader: SxStyle = {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...(theme.mixins.toolbar as any),
    justifyContent: "space-between",
};
export const drawer: SxStyle = {
    background: "#fff",
};
export const filtersTitle: SxStyle = {
    flex: 1,
    marginLeft: theme.spacing(4),
};
