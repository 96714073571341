import { SxStyle } from "src/theme/styles";

export const containerSx: SxStyle = {
    minHeight: "100vh",
    padding: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    transition: "all .3s",
};

export const cardSx: SxStyle = {
    width: 500,
    maxWidth: "100vw",
    minHeight: "40vh",
    display: "flex",
    position: "relative",
    transition: "all .3s",
};
