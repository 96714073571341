import { Box, Stack, Typography } from "@mui/material";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import useTranslationMessages from "src/translations/useMessages";

export const ErrorBoundaryFallback = () => {
    const t = useTranslationMessages();
    return (
        <Stack
            direction="row"
            width={1}
            height={1}
            alignItems="center"
            justifyContent="center">
            <Box textAlign="center">
                <BuildCircleIcon color="error" sx={{ fontSize: 120 }} />
                <Typography variant="h4">{t.errorBoundaryTitle}</Typography>
                <Typography
                    variant="body1"
                    component="div"
                    whiteSpace="pre-wrap">
                    {t.errorBoundaryText}
                </Typography>
            </Box>
        </Stack>
    );
};

export default ErrorBoundaryFallback;
