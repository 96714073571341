import { Box, Typography } from "@mui/material";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import CenteredSpinner from "src/components/CenteredSpinner";
import useTranslationMessages from "src/translations/useMessages";
import QuestionnairePageContainer from "../Questionnaire/container";
import QuestionnaireApi from "src/redux/entities/Questionnaire/api";
import { useParams } from "react-router-dom";
import ObjectiveQuestionnaireApi from "../../../../reduxV2/entities/ObjectiveQuestionnaire";
import { useEffect } from "react";

export interface ClientQuestionnaireProps {
    id?: string;
}

export const ClientQuestionnairePage = (props: ClientQuestionnaireProps) => {
    //const { id } = props;
    const t = useTranslationMessages();
    const { id, token, clientId, lang } = useParams();
    const questionnaire =
        ObjectiveQuestionnaireApi.useGetObjectiveQuestionnaireForClientByIdQueryByQuestionnaireIdAndLanguageQuery({
                        questionnaireId: +id,
                        language: lang,
                        headers: {
                            "X-Api-Key": token,
                            "Client-Id": clientId,
                        },
                    })


    if (questionnaire.isLoading) return <CenteredSpinner />;
    //if (!questionnaire.data) return <Box>{t.noQuestionnaireMsg}</Box>;
    if (questionnaire.isError)
        return (
            <Box p={4} textAlign="center">
                <BeenhereIcon
                    color="success"
                    sx={{ width: 150, height: 150, mb: 5 }}
                />
                <Typography variant="h6" component="div">
                    {t.youHaveAnsweredForThisQuestionnaire}
                </Typography>
            </Box>
        );


    return (
        <Box p={4}>
            <QuestionnairePageContainer
                questionnaire={questionnaire}
            />
        </Box>
    );
};

export default ClientQuestionnairePage;
