export const palette = {
    neutral: {
        50: "#FFF",
        100: "#f5f7fa",
        300: "#B8C0CC",
        500: "#64748B",
        600: "#4B5768",
        800: "#191D23",
    },
    secondary: {
        50: "#EE2239",
        100: "#D51F33",
        300: "#C81D30",
        500: "#AF192A",
        600: "#961624",
        800: "#8A1421",
    },
    primary: {
        50: "#ffeceb",
        100: "#ffd9d7",
        300: "#ffb3af",
        500: "#fe4038",
        600: "#fe241b",
        800: "#fe241b",
    },
    accentPrimary: {
        50: "#e9f2ff",
        100: "#9DEFFF",
        300: "#9DEFFF",
        500: "#00B4D9",
        600: "#008CA8",
        800: "#006377",
    },
    accentSecondary: {
        50: "#F4DFFF",
        100: "#E4AFFF",
        300: "#CE6EFF",
        500: "#BE3EFF",
        600: "#B116FF",
        800: "#4F0077",
    },
    success: {
        50: "#ECFDF5",
        100: "#D1FAE5",
        300: "#6EE7B7",
        500: "#10B981",
        600: "#059669",
        800: "#065F46",
    },
    warning: {
        50: "#FFFBEB",
        100: "#FEF3C7",
        300: "#FCD34D",
        400: "#FBBF24",
        500: "#F59E0B",
        600: "#D97706",
        800: "#92400E",
    },
    error: {
        50: "#FEF2F2",
        100: "#FEE2E2",
        300: "#FCA5A5",
        500: "#EF4444",
        600: "#DC2626",
        800: "#DC2626",
    },
    shades: {
        0: "#FFFFFF",
        100: "#000000",
    },
    darkMode: {
        50: "#3A3C43",
        100: "#373A41",
        200: "#35373E",
        300: "#30333A",
        400: "#2E3138",
        500: "#292C33",
    },
};

export default palette;
