import { css } from "@emotion/react";

export const styles = {
	container: css({
		width: "100%",
		height: "100%",
		flex: 1,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
	}),
}

export default styles;