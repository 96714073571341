import CircularProgress from "@mui/material/CircularProgress";
import { ComponentProps } from "react";

export type SpinnerProps = ComponentProps<typeof CircularProgress>;
export const Spinner = (props: SpinnerProps) => {
    const { color = "primary", ...other } = props;
    return <CircularProgress color={color} {...other} />;
};

Spinner.displayName = "Spinner";

export default Spinner;
