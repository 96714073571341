import { createApi } from "@reduxjs/toolkit/query/react";
import getBaseQuery from "../getBaseQuery";
import {
    ClientSubjectiveQuestionnaire,
    SubjectiveQuestionnaire,
    UpdateSubjectiveQuestionnaireInput,
    CreateSubjectiveQuestionnaireInput,
    ClientSubjectiveQuestionnaireAnswersInput,
    AddSubjectiveQuestionnaireResultInput,
    ClientSubjectiveQuestionnairesResult,
    GetClientSubjectiveQuestionnairesResultsInput,
} from "./entity";

const tag = "SubjectiveQuestionnaire";
const clientTag = "ClientSubjectiveQuestionnaire";
const sessionTag = "SessionSubjectiveQuestionnaire";
const resTag = "ClientSubjectiveQuestionnaireRes";
const listTag = `list_${tag}`;

const SubjectiveQuestionnaireApi = createApi({
    reducerPath: tag,
    baseQuery: getBaseQuery(),
    tagTypes: [tag, clientTag, resTag, sessionTag],
    endpoints: (build) => ({
        addSubjectiveQuestionnaire: build.mutation<
            SubjectiveQuestionnaire,
            CreateSubjectiveQuestionnaireInput
        >({
            query: (subjectiveQuestionnaire) => ({
                url: `/subjective-questionnaire-definitions/`,
                method: "POST",
                body: subjectiveQuestionnaire,
            }),
            transformResponse: (response) =>
                response as SubjectiveQuestionnaire,
            invalidatesTags: (result, error, input) => [
                { type: tag, id: listTag },
            ],
        }),
        updateSubjectiveQuestionnaire: build.mutation<
            SubjectiveQuestionnaire,
            UpdateSubjectiveQuestionnaireInput
        >({
            query: ({ id, ...subjectiveQuestionnaire }) => ({
                url: `/subjective-questionnaire-definitions/${id}`,
                method: "PUT",
                body: subjectiveQuestionnaire,
            }),
            transformResponse: (response) =>
                response as SubjectiveQuestionnaire,
            invalidatesTags: (result, error, input) => [
                { type: tag, id: listTag },
            ],
        }),
        deleteSubjectiveQuestionnaire: build.mutation<void, string>({
            query: (id) => ({
                url: `/subjective-questionnaire-definitions/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, input) => [
                { type: tag, id: listTag },
            ],
        }),
        listSubjectiveQuestionnaires: build.query<
            SubjectiveQuestionnaire[],
            void
        >({
            query: () => {
                return {
                    url: `/subjective-questionnaire-definitions/?size=1000000`,
                };
            },
            transformResponse: (response) =>
                response && Array.isArray(response) ? [...response] : [],
            providesTags: (result, error, input) =>
                result && Array.isArray(result)
                    ? [
                          ...result.map(
                              ({ id }) => ({ type: tag, id } as const),
                          ),
                          { type: tag, id: listTag },
                      ]
                    : [{ type: tag, id: listTag }],
        }),
        listSessionSubjectiveQuestionnaires: build.query<
            ClientSubjectiveQuestionnaire[],
            string
        >({
            query: (sessionId) => {
                return {
                    url: `/subjective-questionnaires/?sessionId=${sessionId}`,
                };
            },
            transformResponse: (response) =>
                response && Array.isArray(response) ? [...response] : [],
            providesTags: (result, error, input) =>
                result && Array.isArray(result)
                    ? [
                          ...result.map(
                              ({ id }) => ({ type: tag, id } as const),
                          ),
                          { type: tag, id: listTag },
                      ]
                    : [{ type: tag, id: listTag }],
        }),
        getSubjectiveQuestionnaireDefinition: build.query<
            SubjectiveQuestionnaire,
            string
        >({
            query: (id) => ({
                url: `/subjective-questionnaire-definitions/${id}`,
            }),
            transformResponse: (response) =>
                response as SubjectiveQuestionnaire,
            providesTags: (result, error, id) => [{ type: tag, id }],
        }),
        getClientSubjectiveQuestionnaire: build.query<
            ClientSubjectiveQuestionnaire,
            string
        >({
            query: (id) => ({ url: `/subjective-questionnaires/${id}` }),
            transformResponse: (response) =>
                response as ClientSubjectiveQuestionnaire,
            providesTags: (result, error, id) => [{ type: clientTag, id }],
        }),
        clientAnswer: build.mutation<
            ClientSubjectiveQuestionnaire,
            ClientSubjectiveQuestionnaireAnswersInput
        >({
            query: (input) => {
                const { id, ...body } = input;
                return {
                    url: `/subjective-questionnaires/${id}`,
                    method: "POST",
                    body,
                };
            },
            transformResponse: (response) =>
                response as ClientSubjectiveQuestionnaire,
            invalidatesTags: () => [clientTag],
        }),
        addResult: build.mutation<void, AddSubjectiveQuestionnaireResultInput>({
            query: (input) => ({
                url: "/subjective-questionnaires",
                method: "POST",
                body: input,
            }),
        }),
        getQuestionnairesChartsData: build.query<
            ClientSubjectiveQuestionnairesResult[],
            GetClientSubjectiveQuestionnairesResultsInput
        >({
            query: ({ clientId, definitionId }) =>
                `/clients/${clientId}/questionnaire-charts/subjective/${definitionId}`,
            transformResponse: (response: any) =>
                [
                    ...(response?.data || []),
                ] as ClientSubjectiveQuestionnairesResult[],
            providesTags: (result, error, input) =>
                result && Array.isArray(result)
                    ? [
                          ...result.map(
                              (res, i) =>
                                  ({
                                      type: resTag,
                                      id: `${input.clientId}_${input.definitionId}_${i}`,
                                  } as const),
                          ),
                          {
                              type: resTag,
                              id: `list_${resTag}_${input.clientId}_${input.definitionId}`,
                          },
                      ]
                    : [
                          {
                              type: resTag,
                              id: `list_${resTag}_${input.clientId}_${input.definitionId}`,
                          },
                      ],
        }),
    }),
});

export default SubjectiveQuestionnaireApi;
