import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import {cardSx, containerSx} from "../styles";
import useTranslationMessages from "src/translations/useMessages";
import CenteredSpinner from "src/components/CenteredSpinner";
import {useNavigate} from "react-router-dom";
import routes from "src/routes";
import {useSnackbar} from "notistack";
import InteractiveBackground from "../../../components/InteractiveBackground";

export interface UserActivationProps {
    userId: string;
    activationCode: string;
}

export const UserActivation = (props: UserActivationProps) => {
    const {userId, activationCode} = props;
    const t = useTranslationMessages();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const a = async () => {
            try {
                const res = await fetch(
                    `${process.env["REACT_APP_API_URL"]}/users/${userId}/activate`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            activationCode,
                        }),
                    },
                );
                if (res.status === 200) {
                    enqueueSnackbar(t.activationSuccessfullMsg, {
                        variant: "success",
                    });
                    navigate(routes.login.path);
                } else {
                    throw new Error();
                }
            } catch {
                enqueueSnackbar(t.smthWentWrongPlsContactSupport, {
                    variant: "error",
                });
            }
            setLoading(false);
        };
        a();
    }, [userId, activationCode, navigate, enqueueSnackbar, t]);
    return (
        <Box sx={containerSx}>
            <InteractiveBackground/>
            <Typography
                variant="h2"
                align="center"
                color="primary"
                marginBottom={5}>
                Measurme
            </Typography>
            <Card sx={cardSx}>
                <CardContent
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <Typography
                        variant="h5"
                        align="center"
                        marginBottom={1}
                        sx={{flex: 0}}>
                        {t.activatingUser}
                    </Typography>
                    {loading ? (
                        <CenteredSpinner sx={{flex: 1}}/>
                    ) : (
                        <Container>
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                marginTop={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{m: 1}}
                                    onClick={() => navigate(routes.login.path)}>
                                    {t.login}
                                </Button>
                            </Grid>
                        </Container>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default UserActivation;
