import {
    Box,
    Button,
    Card,
    CardContent,
    Stack,
    Typography,
} from "@mui/material";
import useTranslationMessages from "src/translations/useMessages";
import {
    useSession,
    AccountStore,
    UserRoles,
    AccountStoreProps,
} from "../store";
import Role from "./Role";
import {useEffect, useState} from "react";
import CenteredSpinner from "src/components/CenteredSpinner";

type SessionRole = AccountStoreProps["session"]["userRolesWithId"][0];

interface RoleSelectorProps {
    dialogMode?: boolean;
    hideDialog?: (e: boolean) => void;
}

const empty: SessionRole[] = [];
export const RoleSelector = (props: RoleSelectorProps) => {
    const t = useTranslationMessages();
    const session = useSession();
    const roles = session?.userRolesWithId || empty;
    const [role, setRole] = useState<SessionRole>(null);
    const onSelect = (roleObj: SessionRole) => () => {
        setRole(roleObj);
    };

    const onConfirm = () => {
        AccountStore.setState((s) => ({
            ...s,
            userId: role.value,
            role: role.key as UserRoles,
        }));
    };

    useEffect(() => {
        if (roles?.length === 1) {
            const r = roles[0];
            AccountStore.setState((s) => ({
                ...s,
                userId: r.value,
                role: r.key as UserRoles,
            }));
        }
    }, [roles]);
    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center">
            <Box minWidth="min(100%, 600px)">
                <Card>
                    <CardContent>
                        {roles.length < 2 ? (
                            <CenteredSpinner/>
                        ) : (
                            <>
                                <Typography
                                    variant="h6"
                                    mb={4}
                                    textAlign="center">
                                    {t.pleaseSelectYourRole}
                                </Typography>
                                <Stack
                                    direction="row"
                                    justifyContent="space-around"
                                    flexWrap="wrap">
                                    {roles.map((r) => (
                                        <Role
                                            key={r.key}
                                            role={r.key}
                                            isSelected={
                                                (role?.key || "") === r.key
                                            }
                                            onSelect={onSelect(r)}
                                        />
                                    ))}
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    mt={4}>
                                    {props.dialogMode && (
                                        <Button
                                            variant="contained"
                                            color="inherit"
                                            onClick={() => props.hideDialog(true)}
                                            sx={{minWidth: 150, marginRight: 2}}>
                                            {t.cancel}
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!role}
                                        onClick={() => {
                                            onConfirm()
                                            if (props.dialogMode) {
                                                console.log('onConfirm')
                                                props.hideDialog(true)
                                            }
                                        }}
                                        sx={{minWidth: 150}}>
                                        {t.confirm}
                                    </Button>
                                </Stack>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Box>
        </Box>
    );
};

export default RoleSelector;
