import moment from "moment";

const a: any = undefined;
const defaultTypes = typeof a;
type ValueType =
    | typeof defaultTypes
    | "null"
    | "array"
    | "map"
    | "set"
    | "moment"
    | "unknown";
type SerializedOutput = {
    type: ValueType;
    value: any;
};

const serializer = (value: any): SerializedOutput => {
    let type: ValueType = typeof value;
    if (type === "undefined")
        return {
            type: undefined,
            value: "",
        };
    if (value === null)
        return {
            type: "null",
            value: "",
        };

    if (type === "function")
        return {
            type,
            value: "",
        };

    if (["string", "number", "boolean"].includes(type))
        return {
            type,
            value,
        };

    if (type === "bigint")
        return {
            type,
            value: value.toString(),
        };

    if (type === "object") {
        if (Array.isArray(value)) type = "array";
        if (value instanceof Map) type = "map";
        if (value instanceof Set) type = "set";
        if (value instanceof moment) type = "moment";
    }

    if (type === "object") {
        return {
            type,
            value: Object.keys(value).map((key) => [
                serialize(key),
                serialize(value[key]),
            ]),
        };
    }

    if (type === "set") {
        return {
            type,
            value: Array.from(value.values()).map((v) => serialize(v)),
        };
    }

    if (type === "map") {
        return {
            type,
            value: Array.from(value.entries()).map((v) => [
                serialize(v[0]),
                serialize(v[1]),
            ]),
        };
    }

    if (type === "array") {
        return {
            type,
            value: value.map((v: any) => serialize(v)),
        };
    }

    if (type === "moment") {
        return {
            type,
            value: value.toISOString(),
        };
    }

    console.warn("Unknown type", value);

    return {
        type: "unknown",
        value: "",
    };
};

export const serialize = (value: any) => {
    const serialized = serializer(value);
    return JSON.stringify(serialized);
};

export const deserialize = (input: string | any[]): any => {
    let parsed: SerializedOutput | null = null;
    let type: ValueType = "unknown";
    let value: any = undefined;
    try {
        if (typeof input === "string") {
            parsed = JSON.parse(input);
            type = parsed.type;
            value = parsed.value;
        } else {
            if (Array.isArray(input)) {
                type = "array";
                value = input;
            } else {
                throw new Error("Deserialize error");
            }
        }
    } catch (e) {
        console.error("deserialize error", input);
        throw e;
    }

    if (type === "unknown") return undefined;

    if (type === "undefined") return undefined;
    if (value === null) return null;

    if (type === "function") return () => undefined;

    if (type === "string") return String(value);

    if (type === "number") return Number(value);

    if (type === "boolean") return Boolean(value);

    if (type === "bigint") return BigInt(value);

    if (type === "array") return value.map((v: string) => deserialize(v));

    if (type === "object") {
        const obj: any = {};
        value.forEach((v) => {
            obj[deserialize(v[0])] = deserialize(v[1]);
        });
        return obj;
    }

    if (type === "set")
        return new Set(value.map((v: string) => deserialize(v)));

    if (type === "map")
        return new Map<any, any>(
            value.map((v) => [deserialize(v[0]), deserialize(v[1])]),
        );
    if (type === "moment") {
        return moment.utc(value);
    }

    return undefined;
};
