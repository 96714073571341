import {EmptyApi as api} from "./codegen.api.base";

export const InjectedPsychologyCenterApi = api.injectEndpoints({
    endpoints: (build) => ({
        AcceptInvite: build.mutation<AcceptInviteApiResponse,
            AcceptInviteArgs>({
            query: (args) => ({
                url: `/api/PsychologyCenter/AcceptInvite`,
                method: "POST",
                body: args.acceptInvite,
            }),
        }),
        SendInvite: build.mutation<SendInviteApiResponse,
            SendInviteArgs>({
            query: (args) => ({
                url: `/api/PsychologyCenter/SendInvite`,
                method: "POST",
                body: args.sendUserInvite,
            }),
        }),
        LockUser: build.mutation<any,
            any>({
            query: (args) => ({
                url: `/api/PsychologyCenter/LockUser`,
                method: "POST",
                body: args,
            }),
        }),
        UnLockUser: build.mutation<any,
            any>({
            query: (args) => ({
                url: `/api/PsychologyCenter/UnLockUser`,
                method: "POST",
                body: args,
            }),
        }),
        GetUsers: build.query<GetUsersApiResponse,
            GetUsersArgs>({
            query: () => ({url: `/api/PsychologyCenter/GetUsers`}),
        }),
        GetTherapistsNames: build.query<GetTherapistsNamesApiResponse,
            GetTherapistsNamesArgs>({
            query: () => ({url: `/api/PsychologyCenter/GetTherapistsNames`}),
        }),
    }),
    overrideExisting: false,
});
export {InjectedPsychologyCenterApi as PsychologyCenterApi};
export type AcceptInviteApiResponse =
/** status 200 Success */ undefined;
export type AcceptInviteArgs = {
    acceptInvite: AcceptInviteInput;
};
export type SendInviteApiResponse =
/** status 200 Success */ undefined;
export type SendInviteArgs = {
    sendUserInvite: SendUserInviteInput;
};
export type GetUsersApiResponse =
/** status 200 Success */ UserDto[];
export type GetUsersArgs = void;
export type GetTherapistsNamesApiResponse =
/** status 200 Success */ TherapistNameDto[];
export type GetTherapistsNamesArgs = void;
export type ApiErrorResponse = {
    errors?: string[] | null;
};
export type AcceptInviteInput = {
    userInviteId?: number;
    verifyToken?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    password?: string | null;
};
export type SendUserInviteInput = {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    role?: string | null;
};
export type UserStatus = "Joined" | "Invited";
export type UserDto = {
    id?: string;
    firstName?: string | null;
    lastName?: string | null;
    userStatus?: UserStatus;
    email?: string | null;
    role?: string | null;
    isBlocked?: boolean;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type TherapistNameDto = {
    id?: number;
    firstName?: string | null;
    lastName?: string | null;
};
export const {
    useAcceptInviteMutation,
    useSendInviteMutation,
    useLockUserMutation,
    useUnLockUserMutation,
    useGetUsersQuery,
    useGetTherapistsNamesQuery,
} = InjectedPsychologyCenterApi;
