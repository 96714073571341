import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select as MuiSelect,
    SelectChangeEvent,
} from "@mui/material";
import {
    ComponentProps,
    Fragment,
    memo,
    ReactNode,
    useCallback,
    useEffect,
} from "react";
import Spinner from "src/components/Spinner";
import useTranslationMessages from "src/translations/useMessages";
import {Validator} from "src/utils/validators/types";

export interface SelectOption {
    value: string;
    label: ReactNode;
    meta?: any;
}

export interface SelectProps {
    label: string;
    value: string;
    options?: SelectOption[];
    groups?: {
        label: string;
        options: SelectOption[];
    }[];
    onChange: (value: string) => void;
    onValidationError?: (message: string) => void;
    required?: boolean;
    error?: string;
    validators?: Validator[];
    formControlProps?: Omit<ComponentProps<typeof FormControl>,
        "children" | "error">;
    loading?: boolean;
    multiple?: boolean;
    disabled?: boolean;
}

export const Select = memo((props: SelectProps) => {
    const {
        label,
        value,
        options,
        onChange,
        onValidationError,
        required,
        error,
        validators,
        formControlProps,
        loading,
        groups,
        multiple,
        disabled
    } = props;
    const t = useTranslationMessages();
    const onSelect = useCallback(
        (e: SelectChangeEvent<string>) => {
            onChange(e.target.value);
        },
        [onChange],
    );
    useEffect(() => {
        let msg = "";
        let validate = false;
        if (required || (value !== "" && validators)) validate = true;
        if (validate) {
            if (validators?.length) {
                for (let validator of validators) {
                    if (!validator.validate(value.toString())) {
                        msg = validator.message(t);
                        break;
                    }
                }
            }
            if (!msg && required) msg = value ? "" : t.required;
        }
        if (error === msg) return;
        if (onValidationError) onValidationError(msg);
    }, [value, validators, required, onValidationError, t, error]);

    const children: ReactNode[] = [
        ...(options || []).map((o) => (
            <MenuItem value={o.value} key={o.value}>
                {o.label}
            </MenuItem>
        )),
    ];
    (groups || []).forEach((g) => {
        children.push(
            <ListSubheader key={g.label}>{g.label}</ListSubheader>,
            ...(g.options || []).map((o) => (
                <MenuItem value={o.value} key={o.value}>
                    {o.label}
                </MenuItem>
            )),
        );
    });

    return (
        <FormControl
            fullWidth
            variant="standard"
            margin="dense"
            error={!!error}
            {...formControlProps}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                label={label}
                value={value}
                onChange={onSelect}
                IconComponent={
                    loading
                        ? () => (
                            <Box>
                                <Spinner size={16}/>
                            </Box>
                        )
                        : undefined
                }
                multiple={multiple}
                disabled={loading || disabled}>
                {children}
                {options?.length < 1 && (<Box sx={{padding: '0 16px'}}>{t.noData}</Box>)}
            </MuiSelect>
            {!loading && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
});

export default Select;
