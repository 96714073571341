import { memo, useEffect } from "react";
import CenteredSpinner from "src/components/CenteredSpinner";
import { PageBreadcrumb, usePageContext } from "src/components/Page/context";
import routes from "src/routes";
import useTranslationMessages from "src/translations/useMessages";
import QuestionnaireView from "./view";
import { Box } from "@mui/material";
import ObjectiveQuestionnaireApi from "../../../../reduxV2/entities/ObjectiveQuestionnaire";

export interface QuestionnairePageContainerProps {
    questionnaire: any;
}
export const QuestionnairePageContainer = memo(
    (props: QuestionnairePageContainerProps) => {
        const { questionnaire } = props;
        const t = useTranslationMessages();
        const { setBreadcrumbs } = usePageContext();

        useEffect(() => {
            const breadcrumbs: PageBreadcrumb[] = [
                {
                    name: t[routes.questionnaire.name],
                    link: routes.questionnaires.path,
                },
            ];
            if (questionnaire.data)
                breadcrumbs.push({
                    name: '',
                    link: routes.questionnaire.getRoute(questionnaire.data.id),
                });
            setBreadcrumbs(breadcrumbs);
        }, [t, setBreadcrumbs, questionnaire.data]);

        if (questionnaire.isLoading) return <CenteredSpinner />;
        if (!questionnaire.data || questionnaire.isError) return <Box>{t.noQuestionnaireMsg}</Box>;
        return (
            <QuestionnaireView
                data={questionnaire.data}
            />
        );
    },
);

export default QuestionnairePageContainer;
