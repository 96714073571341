import { Navigate } from "react-router-dom";
import { useQuestionnaire } from "./useQuestionnaire";
import routes from "src/routes";
import ClientQuestionnairePage from "../Questionnaires/Pages/ClientQuestionnaire";
import ClientSubjectiveQuestionnairePage from "../Questionnaires/Pages/ClientQuestionnaire/Subjective";
import useUserActivation from "./useUserActivation";
import UserActivation from "../Auth/UserActivation";
import { useIsUserLoggedIn } from "src/components/AuthProvider/store";

export const Root = () => {
    const questionnaire = useQuestionnaire();
    const userActivation = useUserActivation();
    const isLoggedIn = useIsUserLoggedIn();

    if (questionnaire?.type === "objective" && questionnaire?.questionnaireId)
        return <ClientQuestionnairePage id={questionnaire.questionnaireId} />;

    if (userActivation) {
        return <UserActivation {...userActivation} />;
    }

    if (!isLoggedIn) {
        <Navigate to={routes.login.path} />;
    }

    return <Navigate to={routes.homepage.path} />;
};

export default Root;
