import {Global} from "@emotion/react";
import {styled, ThemeProvider} from "@mui/material";
import {Provider as ReduxProvider} from "react-redux";
import {AuthProvider} from "./components/AuthProvider";
import styles from "./styles";
import theme from "./theme";
import TranslationProvider from "./translations/TranslationProvider";
import store from "./reduxV2/store";
import {SnackbarOrigin, SnackbarProvider, MaterialDesignContent} from "notistack";
import useSettingsEffects from "./services/settings/useSettingsEffect";
import Router from "./routes/Router";
import palette from "./theme/colors";


const FixedReduxProvider = ReduxProvider as unknown as any;
const snackbarAnchor: SnackbarOrigin = {horizontal: "right", vertical: "top"};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: palette.neutral[50],
        color: palette.neutral[800]
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: palette.neutral[500],
    },
}));

export const App = () => {
    useSettingsEffects();
    return (
        <ThemeProvider theme={theme}>
            <Global styles={styles}/>
            <TranslationProvider>
                <SnackbarProvider
                    Components={{
                        success: StyledMaterialDesignContent,
                        error: StyledMaterialDesignContent,
                    }}
                    maxSnack={5}
                    anchorOrigin={snackbarAnchor}
                    autoHideDuration={5000}>
                    <AuthProvider>
                        <FixedReduxProvider store={store}>
                            <Router/>
                        </FixedReduxProvider>
                    </AuthProvider>
                </SnackbarProvider>
            </TranslationProvider>
        </ThemeProvider>
    );
};

export default App;
