import { ForwardedRef, useCallback, useRef, useState } from "react";
import { DialogRef } from "./types";

export const useDialogControl = <T extends DialogRef>() => {
    const ref = useRef<T>();
    const show = useCallback(() => {
        if (ref?.current?.show) ref.current.show();
    }, []);

    const hide = useCallback(() => {
        if (ref?.current?.hide) ref.current.hide();
    }, []);

    return {
        ref,
        show,
        hide,
    };
};

export const useForwardedDialogControl = <T extends DialogRef>(
    ref: ForwardedRef<T>,
) => {
    const show = useCallback(() => {
        if (typeof ref === "object" && ref?.current?.show) ref.current.show();
    }, [ref]);

    const hide = useCallback(() => {
        if (typeof ref === "object" && ref?.current?.hide) ref.current.hide();
    }, [ref]);
    return {
        show,
        hide,
    };
};

export interface UseDialogProps {
    onHidden?: () => void;
}
export const useDialog = (props?: UseDialogProps) => {
    const { onHidden } = props || {};
    const [open, setOpen] = useState(false);
    const show = useCallback(() => setOpen(true), [setOpen]);
    const hide = useCallback(() => setOpen(false), [setOpen]);
    const onTransitionEnd = useCallback(() => {
        if (onHidden && !open) onHidden();
    }, [open, onHidden]);
    return {
        open,
        setOpen,
        show,
        hide,
        onTransitionEnd,
    };
};
