import { EmptyApi as api } from "./codegen.api.base";
export const InjectedObjectiveQuestionnaireApi = api.injectEndpoints({
    endpoints: (build) => ({
        AddQuestionnaire: build.mutation<
            AddQuestionnaireApiResponse,
            AddQuestionnaireArgs
        >({
            query: (args) => ({
                url: `/api/ObjectiveQuestionnaire/AddQuestionnaire`,
                method: "POST",
                body: args.objectiveQuestionnaireDto,
            }),
        }),
        UpdateQuestionnaire: build.mutation<
            UpdateQuestionnaireApiResponse,
            UpdateQuestionnaireArgs
        >({
            query: (args) => ({
                url: `/api/ObjectiveQuestionnaire/UpdateQuestionnaire`,
                method: "PUT",
                body: args.objectiveQuestionnaireDto,
            }),
        }),
        GetQuestionnaireByIdById: build.query<
            GetQuestionnaireByIdByIdApiResponse,
            GetQuestionnaireByIdByIdArgs
        >({
            query: (args) => ({
                url: `/api/ObjectiveQuestionnaire/GetQuestionnaireById/${args.id}`,
            }),
        }),
        GetObjectiveQuestionnaireForClientByIdQueryByQuestionnaireIdAndLanguage:
            build.query<
                GetObjectiveQuestionnaireForClientByIdQueryByQuestionnaireIdAndLanguageApiResponse,
                GetObjectiveQuestionnaireForClientByIdQueryByQuestionnaireIdAndLanguageArgs
            >({
                query: (args) => ({
                    url: `/api/ObjectiveQuestionnaire/GetObjectiveQuestionnaireForClientByIdQuery/${args.questionnaireId}/${args.language}`,
                    headers: args.headers
                }),
            }),
        GetQuestionnaireByTherapistIdByTherapistId:
            build.query<
                GetQuestionnaireByTherapistIdByTherapistIdApiResponse,
                GetQuestionnaireByTherapistIdByTherapistIdArgs
            >({
                query: (args) => ({
                    url: `/api/ObjectiveQuestionnaire/GetQuestionnaireByTherapistId/${args.therapistId}`,
                }),
            }),
        SendObjectiveQuestionnaireToClient:
            build.mutation<
                SendObjectiveQuestionnaireToClientApiResponse,
                SendObjectiveQuestionnaireToClientArgs
            >({
                query: (args) => ({
                    url: `/api/ObjectiveQuestionnaire/SendObjectiveQuestionnaireToClient`,
                    method: "POST",
                    body: args.sendObjectiveQuestionnaireToClient,
                }),
            }),
        SentAnswersForClientQuestionnaire:
            build.mutation<
                SentAnswersForClientQuestionnaireApiResponse,
                SentAnswersForClientQuestionnaireArgs
            >({
                query: (args) => ({
                    url: `/api/ObjectiveQuestionnaire/SentAnswersForClientQuestionnaire`,
                    method: "POST",
                    body: args.setAnswersForClientQuestionnaire,
                    headers: args.headers
                }),
            }),
        DeleteQuestionnaireByQuestionnaireId:
            build.mutation<
                DeleteQuestionnaireByQuestionnaireIdApiResponse,
                DeleteQuestionnaireByQuestionnaireIdArgs
            >({
                query: (args) => ({
                    url: `/api/ObjectiveQuestionnaire/DeleteQuestionnaire/${args.questionnaireId}`,
                    method: "DELETE",
                }),
            }),
    }),
    overrideExisting: false,
});
export { InjectedObjectiveQuestionnaireApi as ObjectiveQuestionnaireApi };
export type AddQuestionnaireApiResponse =
    /** status 200 Success */ ObjectiveQuestionnaireDto;
export type AddQuestionnaireArgs = {
    objectiveQuestionnaireDto: ObjectiveQuestionnaireDto;
};
export type UpdateQuestionnaireApiResponse =
    /** status 200 Success */ ObjectiveQuestionnaireDto;
export type UpdateQuestionnaireArgs = {
    objectiveQuestionnaireDto: ObjectiveQuestionnaireDto;
};
export type GetQuestionnaireByIdByIdApiResponse =
    /** status 200 Success */ ObjectiveQuestionnaireDto;
export type GetQuestionnaireByIdByIdArgs = {
    id: number;
};
export type GetObjectiveQuestionnaireForClientByIdQueryByQuestionnaireIdAndLanguageApiResponse =
    /** status 200 Success */ ObjectiveQuestionnaireDto;
export type GetObjectiveQuestionnaireForClientByIdQueryByQuestionnaireIdAndLanguageArgs =
    {
        questionnaireId: number;
        language: string;
        headers?: {
            "X-Api-Key": string;
            "Client-Id": string;
        }
    };
export type GetQuestionnaireByTherapistIdByTherapistIdApiResponse =
    /** status 200 Success */ ObjectiveQuestionnaireListResponse[];
export type GetQuestionnaireByTherapistIdByTherapistIdArgs =
    {
        therapistId: number;
    };
export type SendObjectiveQuestionnaireToClientApiResponse =
    /** status 200 Success */ undefined;
export type SendObjectiveQuestionnaireToClientArgs =
    {
        sendObjectiveQuestionnaireToClient: SendObjectiveQuestionnaireToClientInput;
    };
export type SentAnswersForClientQuestionnaireApiResponse =
    /** status 200 Success */ undefined;
export type SentAnswersForClientQuestionnaireArgs =
    {
        setAnswersForClientQuestionnaire: SetAnswersForClientQuestionnaireInput;
        headers: {
            "X-Api-Key": string;
            "Client-Id": string;
        }
    };
export type DeleteQuestionnaireByQuestionnaireIdApiResponse =
    /** status 200 Success */ undefined;
export type DeleteQuestionnaireByQuestionnaireIdArgs =
    {
        questionnaireId: number;
    };
export type ObjectiveQuestionnaireDefinitionDto = {
    id: number;
    objectiveQuestionnaireId: number;
    title: string;
    description: string;
    language: string;
};
export type ObjectiveOptionDefinitionDto = {
    id?: number;
    description: string;
    language: string;
};
export type ObjectiveOptionDto = {
    id: number;
    index: number;
    objectiveQuestionId: number;
    score: number;
    objectiveOptionDefinitions: ObjectiveOptionDefinitionDto[];
};
export type ObjectiveQuestionDefinitionDto = {
    objectiveQuestionId: number;
    id: number;
    description: string;
    language: string;
};
export type ObjectiveQuestionDto = {
    id: number;
    index: number;
    objectiveQuestionnaireId: number;
    objectiveQuestionnaireOptions: ObjectiveOptionDto[];
    objectiveQuestionnaireQuestionDefinitions: ObjectiveQuestionDefinitionDto[];
};
export type ObjectiveQuestionnaireDto = {
    id: number;
    objectiveQuestionnaireDefinitions: ObjectiveQuestionnaireDefinitionDto[];
    objectiveQuestionnaireQuestions: ObjectiveQuestionDto[];
    createdAt: string;
    therapistId: number;
};
export type ApiErrorResponse = {
    errors?: string[] | null;
};
export type ProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type ObjectiveQuestionnaireDefinitionListResponse = {
    id?: number;
    objectiveQuestionnaireId?: number;
    title?: string | null;
    description?: string | null;
    language?: string | null;
};
export type ObjectiveQuestionnaireListResponse = {
    id?: number;
    objectiveQuestionnaireDefinitions?:
        | ObjectiveQuestionnaireDefinitionListResponse[]
        | null;
    createdAt?: string;
    therapistId?: number;
};
export type SendObjectiveQuestionnaireToClientInput = {
    clientId: number;
    objectiveQuestionnaireId: number;
    deadline: string;
    language: string;
    sessionId: number;
};
export type SetAnswersForClientQuestionnaireInput = {
    clientQuestionnaireId: number;
    answers: AnswerForClientQuestionnaire[];
};
export type AnswerForClientQuestionnaire = {
    question: string;
    answer: string;
    score: number;
};
export const {
    useAddQuestionnaireMutation,
    useUpdateQuestionnaireMutation,
    useGetQuestionnaireByIdByIdQuery,
    useGetObjectiveQuestionnaireForClientByIdQueryByQuestionnaireIdAndLanguageQuery,
    useGetQuestionnaireByTherapistIdByTherapistIdQuery,
    useSendObjectiveQuestionnaireToClientMutation,
    useSentAnswersForClientQuestionnaireMutation,
    useDeleteQuestionnaireByQuestionnaireIdMutation,
} = InjectedObjectiveQuestionnaireApi;
