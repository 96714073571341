export function checkDescriptionsFilled(obj) {

    function checkObject(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && key === 'description' && obj[key] === "") {
                return false;
            }
            if (typeof obj[key] === 'object' && !checkObject(obj[key])) {
                return false;
            }
        }
        return true;
    }

    function checkArray(arr) {
        for (let i = 0; i < arr.length; i++) {
            if (!checkObject(arr[i])) {
                return false;
            }
        }
        return true;
    }

    function checkNestedArrays(arr) {
        for (let i = 0; i < arr.length; i++) {
            if (Array.isArray(arr[i])) {
                if (!checkNestedArrays(arr[i])) {
                    return false;
                }
            } else if (typeof arr[i] === 'object' && !checkObject(arr[i])) {
                return false;
            }
        }
        return true;
    }

    if (!checkObject(obj)) {
        return false;
    }

    if (
        'objectiveQuestionnaireDefinitions' in obj &&
        !checkNestedArrays(obj.objectiveQuestionnaireDefinitions)
    ) {
        return false;
    }

    if (
        'objectiveQuestionnaireQuestions' in obj &&
        !checkNestedArrays(obj.objectiveQuestionnaireQuestions)
    ) {
        return false;
    }

    return true;
}

export function checkMissingFields(obj) {
    let missingLang = '';

    function checkObject(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && key === 'description' && obj[key] === "") {
                missingLang = obj.language
            }
            if (typeof obj[key] === 'object' && !checkObject(obj[key])) {
                return false;
            }
        }
        return true;
    }

    function checkNestedArrays(arr) {
        for (let i = 0; i < arr.length; i++) {
            if (Array.isArray(arr[i])) {
                if (!checkNestedArrays(arr[i])) {
                    return false;
                }
            } else if (typeof arr[i] === 'object' && !checkObject(arr[i])) {
                return false;
            }
        }
        return true;
    }

    if (!checkObject(obj)) {
        return missingLang;
    }

    if (
        'objectiveQuestionnaireDefinitions' in obj &&
        !checkNestedArrays(obj.objectiveQuestionnaireDefinitions)
    ) {
        return missingLang;
    }

    if (
        'objectiveQuestionnaireQuestions' in obj &&
        !checkNestedArrays(obj.objectiveQuestionnaireQuestions)
    ) {
        return missingLang;
    }

    return missingLang;
}

