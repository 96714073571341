import {Suspense} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import CenteredSpinner from "../components/CenteredSpinner";
import routes from "../routes";
import getSuspendedProtectedRoute from "../routes/getSuspendedProtectedRoute";
import getSuspendedElement from "../utils/getSuspendedElement";
import SubjectiveClientQuestionnairePage from "../pages/Questionnaires/Pages/ClientQuestionnaire/Subjective";

const PrivacyPolicy = getSuspendedElement(
    () => import("../pages/Terms/privacyPolicy"),
);
const TermsOfService = getSuspendedElement(
    () => import("../pages/Terms/terms"),
);
const LoginPage = getSuspendedElement(() => import("../pages/Auth/Login"));
const SignUpPage = getSuspendedElement(() => import("../pages/Auth/Signup"));
const ForgotPasswordPage = getSuspendedElement(
    () => import("../pages/Auth/ForgotPassword"),
);
const ResetPasswordPage = getSuspendedElement(
    () => import("../pages/Auth/ResetPassword"),
);

const ClientObjectiveQuestionnaire = getSuspendedElement(
    () => import("../pages/Questionnaires/Pages/ClientQuestionnaire"),
);
const ClientSubjectiveQuestionnaire = getSuspendedElement(
    () => import("../pages/Questionnaires/Pages/ClientQuestionnaire/Subjective"),
);
const ConfirmEmailPage = getSuspendedElement(
    () => import("../pages/Auth/ConfirmEmail"),
);
const AcceptInvite = getSuspendedElement(
    () => import("../pages/Auth/AcceptInvite"),
);

export const UnAuthRouter = () => (
    <BrowserRouter>
        <Suspense fallback={<CenteredSpinner/>}>
            <Routes>
                <Route
                    path={routes.clientObjectiveQuestionnaire.path}
                    element={<ClientObjectiveQuestionnaire/>}
                />
                <Route
                    path={routes.clientSubjectiveQuestionnaire.path}
                    element={<ClientSubjectiveQuestionnaire/>}
                />
                <Route
                    path={routes.privacyPolicy.path}
                    element={<PrivacyPolicy/>}
                />
                <Route
                    path={routes.termsOfService.path}
                    element={<TermsOfService/>}
                />
                <Route path={routes.login.path} element={<LoginPage/>}/>
                <Route path={routes.signUp.path} element={<SignUpPage/>}/>
                <Route
                    path={routes.forgotPassword.path}
                    element={<ForgotPasswordPage/>}
                />
                <Route
                    path={routes.resetPassword.path}
                    element={<ResetPasswordPage/>}
                />
                <Route
                    path={routes.confirmEmail.path}
                    element={<ConfirmEmailPage/>}
                />
                <Route
                    path={routes.acceptInvite.path}
                    element={<AcceptInvite/>}
                />
                <Route path="*" element={<Navigate to={routes.login.path}/>}/>
            </Routes>
        </Suspense>
    </BrowserRouter>
);

export default UnAuthRouter;
