import {Box} from "@mui/material";
import FormInput from "src/components/Form/Input";
import {FormData} from "src/components/Form/useFormData";
import {AddClientInput} from "src/reduxV2/entities/Client";
import useTranslationMessages from "src/translations/useMessages";
import {emailValidator, minLength, phoneValidator} from "src/utils/validators/string";
import {AcceptInviteInput, SendUserInviteInput} from "../../../reduxV2/entities/PsychologyCenter";
import Select from "../../../components/Form/Select";
import {UserRoles} from "../../../components/AuthProvider/store";
import RoleSelect from "../../../components/Form/RoleSelect";
import AcceptInvite from "./index";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import {useState} from "react";
import FormPasswordInput from "../../../components/Form/Password";

type AcceptInviteFormData = FormData<AcceptInviteInput>;

export type AcceptInviteFormProps = Required<Pick<AcceptInviteFormData, "values" | "errors" | "setValue" | "setError">>;

export const AcceptInviteForm = (props: AcceptInviteFormProps) => {
    const {values, errors, setValue, setError} = props;

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const t = useTranslationMessages();
    return (
        <Box>
            <FormInput
                autocomplete={'off'}
                label={t.firstName}
                required
                value={values.firstName}
                error={errors.firstName}
                onValidationError={setError("firstName")}
                onChange={setValue("firstName")}
            />
            <FormInput
                autocomplete={'off'}
                label={t.lastName}
                required
                value={values.lastName}
                error={errors.lastName}
                onValidationError={setError("lastName")}
                onChange={setValue("lastName")}
            />
            <FormPasswordInput
                required
                label={t.password}
                value={values.password}
                onChange={setValue("password")}
                onValidationError={setError("password")}
                validators={[minLength(5)]}
                error={errors.password}
            />
        </Box>
    );
};

export default AcceptInviteForm;
