import createStore from "src/utils/store";

export interface SettingsProps {
    enableHiddenFeatures: boolean;
    enableHiddenSettings: boolean;
}

export const factorySettings: SettingsProps = {
    enableHiddenFeatures: false,
    enableHiddenSettings: false,
};

export const SettingsStore = createStore<SettingsProps>(factorySettings);

export const useSettings = SettingsStore.useStore;
