import {memo, useCallback, useEffect} from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import QuizIcon from "@mui/icons-material/Quiz";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import * as styles from "./styles";
import routes from "src/routes";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useTranslationMessages from "src/translations/useMessages";
import {Badge, Box, ListItemButton} from "@mui/material";
import {useSettings} from "src/services/settings";
import {Analytics, CreditCard, Money, People} from "@mui/icons-material";
import {useAccount, UserRoles} from "../AuthProvider/store";

export interface MenuDrawerProps {
    isMobile: boolean;
    open: boolean;
    onNavigate: () => void;
    handleDrawerClose: () => void;
}

export const MenuDrawer = memo((props: MenuDrawerProps) => {
    const {isMobile, open, handleDrawerClose, onNavigate} = props;
    const enableHiddenFeatures = useSettings((s) => s.enableHiddenFeatures);
    const role = useAccount((s) => s.role);
    const t = useTranslationMessages();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const goTo = useCallback(
        (to: string) => () => {
            onNavigate();
            navigate(to);
        },
        [navigate, onNavigate],
    );

    const forEveryone = [UserRoles.secretary, UserRoles.therapist, UserRoles.supervisor, UserRoles.administrator, UserRoles.director]

    const menuItem = [
        {
            path: routes.summary.path,
            icon: <Analytics sx={{color: pathname === routes.summary.path && 'red!important'}}/>,
            label: t.analytics,
            forRoles: [UserRoles.director]
        },
        {
            path: routes.clients.path,
            icon: <ContactPageIcon sx={{color: pathname === routes.clients.path && 'red!important'}}/>,
            label: t.clients,
            forRoles: forEveryone
        },
        {
            path: routes.calendar.path,
            icon: <CalendarMonthIcon sx={{color: pathname === routes.calendar.path && 'red!important'}}/>,
            label: t.calendar,
            forRoles: forEveryone
        },
        {
            path: routes.questionnaires.path,
            icon: <QuizIcon sx={{color: pathname === routes.questionnaires.path && 'red!important'}}/>,
            label: t.questionnaires,
            forRoles: forEveryone
        },
        {
            path: routes.users.path,
            icon: <People sx={{color: pathname === routes.users.path && 'red!important'}}/>,
            label: t.users,
            forRoles: [UserRoles.director, UserRoles.secretary]
        },
        {
            path: routes.transactions.path,
            icon: <CreditCard sx={{color: pathname === routes.transactions.path && 'red!important'}}/>,
            label: t.transactions,
            forRoles: [UserRoles.director, UserRoles.secretary]
        }
    ]

    useEffect(() => {
        if (pathname === routes.calendar.path) {
            handleDrawerClose()
        }
    }, [pathname])

    return (
        <Drawer
            sx={{
                width: styles.drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: styles.drawerWidth,
                    boxSizing: "border-box",
                    background: "#fff",
                    color: "black",
                    "& .MuiListItemIcon-root": {
                        color: "black",
                    },
                    "& .MuiListItemIcon-root active": {
                        color: "red",
                    },
                },
            }}
            variant={isMobile ? "temporary" : "persistent"}
            anchor="left"
            open={open}
            onClose={isMobile ? handleDrawerClose : undefined}>
            <Box sx={styles.drawerHeader}>
                <Typography
                    variant="h6"
                    color="primary"
                    align="left"
                    paddingLeft={1}>
                    Measurme
                </Typography>
                <IconButton onClick={handleDrawerClose} sx={{color: "black"}}>
                    <ChevronLeftIcon/>
                </IconButton>
            </Box>
            <Divider/>
            <List>
                {menuItem.map((item) => {
                    if (item.forRoles.includes(role)) {
                        return (
                            <ListItemButton onClick={goTo(item.path)}
                                            sx={{color: pathname === item.path && 'red!important'}}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.label}/>
                            </ListItemButton>
                        )
                    }
                })}
            </List>
        </Drawer>
    );
});

export default MenuDrawer;
