import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import AccountStore from "src/components/AuthProvider/store";
// import getAuthSession from "src/components/AuthProvider/service";

export const getBaseQuery = () =>
    fetchBaseQuery({
        baseUrl: process.env["REACT_APP_API_URL"],
        prepareHeaders: (headers) => {
            headers.set("accept", "application/json");
            const token = AccountStore.getState().session.accessToken;
            headers.set("authorization", `Bearer ${token}`);
            return headers;
        },
    });

export default getBaseQuery;
