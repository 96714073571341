import { GetQuestionnaireByIdByIdArgs, InjectedObjectiveQuestionnaireApi } from "./codegen.api";

export * from "./codegen.api";

const type = "ObjectiveQuestionnaire";
const questionnaireList = "ObjectiveQuestionnaireList";

export const ObjectiveQuestionnaireApi = InjectedObjectiveQuestionnaireApi.enhanceEndpoints({
    addTagTypes: [type, questionnaireList],
    endpoints: {
        AddQuestionnaire: {
            invalidatesTags: (respose, error, args) => [
                { type: questionnaireList },
            ],
        },
        UpdateQuestionnaire: {
            invalidatesTags: (respose, error, args) => [
                { type: questionnaireList },
            ],
        },
        GetQuestionnaireByIdById: {
            providesTags: (respose, error, args) => [
                { type: questionnaireList },
            ],
        },
        GetQuestionnaireByTherapistIdByTherapistId: {
            providesTags: (respose, error, args) => [
                { type: questionnaireList },
            ],
        },
        DeleteQuestionnaireByQuestionnaireId: {
            invalidatesTags: (respose, error, args) => [
                { type: questionnaireList },
            ],
        },
    },
});
export default ObjectiveQuestionnaireApi;
