import { ReactNode, memo, useEffect, useState } from "react";
import * as moment from "moment";
import {
    createIntl,
    createIntlCache,
    RawIntlProvider,
    IntlShape,
} from "react-intl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ukUA } from "@mui/x-date-pickers/locales";
import { Language, LanguageStore, useLanguage } from "./store";
import TranslationMessages from "./messages";
import CenteredSpinner from "src/components/CenteredSpinner";
import FormatMessageUpdater from "./FormatMessageUpdater";
import "moment/locale/uk";

const LANGUAGE = "app_language";

const getMessages = async (language: Language) => {
    let messages: TranslationMessages =
        language === "uk"
            ? (await import("./ua")).default
            : (await import("./en")).default;
    return messages;
};

export interface TranslationProviderProps {
    children: ReactNode;
}
export interface TranslationProviderState {
    intl: IntlShape | null;
    language: Language;
}
export const TranslationProvider = memo((props: TranslationProviderProps) => {
    const [intl, setIntl] = useState<null | IntlShape>(null);
    const [isReady, setIsReady] = useState(false);
    const language = useLanguage();
    useEffect(() => {
        const l = (localStorage.getItem(LANGUAGE) as any) || "en";
        LanguageStore.setValue("language", l);
        setIsReady(true);
    }, []);

    useEffect(() => {
        if (!isReady) return;
        localStorage.setItem(LANGUAGE, language);
        getMessages(language as Language)
            .then((messages) => {
                moment.locale(language);
                moment.updateLocale(language, {
                    week: {
                        dow: 1, // Monday is the first day of the week.
                    },
                });
                LanguageStore.setValue("currentTranslation", messages);
                const intl = createIntl(
                    {
                        locale: language,
                        messages: messages,
                    },
                    createIntlCache(),
                );
                setIntl(intl);
            })
            .catch((e) => {
                console.warn(e);
            });
    }, [language, isReady]);

    if (!intl || !language) return <CenteredSpinner />;
    return (
        <RawIntlProvider value={intl}>
            <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={language}
                localeText={
                    language === "uk"
                        ? ukUA.components.MuiLocalizationProvider.defaultProps
                              .localeText
                        : undefined
                }
                key={language}>
                <FormatMessageUpdater />
                {props.children}
            </LocalizationProvider>
        </RawIntlProvider>
    );
});

export default TranslationProvider;
