import { ReactNode } from "react";
import { UserRoles, useIsUserLoggedIn, useUserId, useUserRole } from "../store";
import RoleSelector from "./RoleSelector";

export interface PageRoleCheckerProps {
    children: ReactNode;
}
export const PageRoleChecker = (props: PageRoleCheckerProps) => {
    const isLoggedIn = useIsUserLoggedIn();
    const userId = useUserId();
    const role = useUserRole();

    if (!isLoggedIn) return null;
    if (!userId || role === UserRoles.none) {
        return <RoleSelector />;
    }

    return <>{props.children}</>;
};

export default PageRoleChecker;
