import { ReactNode, memo } from "react";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useTranslationMessages from "src/translations/useMessages";
import * as styles from "./styles";
import { Box } from "@mui/material";

export interface FiltersDrawerProps {
    isMobile: boolean;
    isFiltersOpen: boolean;
    hideFilters: () => void;
    filters: ReactNode;
}

export const FiltersDrawer = memo((props: FiltersDrawerProps) => {
    const { isMobile, isFiltersOpen, filters, hideFilters } = props;
    const t = useTranslationMessages();
    return (
        <Drawer
            sx={{
                width: styles.drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: styles.drawerWidth,
                    boxSizing: "border-box",
                },
            }}
            variant={isMobile ? "temporary" : "persistent"}
            anchor="right"
            open={isFiltersOpen}
            onClose={isMobile ? hideFilters : undefined}>
            <Box sx={styles.drawerHeader}>
                <IconButton onClick={hideFilters}>
                    <ChevronRightIcon />
                </IconButton>
                <Typography
                    variant="h6"
                    color="secondary"
                    align="center"
                    paddingLeft={1}
                    sx={styles.filtersTitle}>
                    {t.filters}
                </Typography>
            </Box>
            <Divider />
            <Box
                width={1}
                flex={1}
                sx={{ overflowX: "hidden", overflowY: "auto" }}>
                {filters}
            </Box>
        </Drawer>
    );
});

export default FiltersDrawer;
