import {Box, Stack, Typography} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import BusinessIcon from "@mui/icons-material/Business";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {UserRoles} from "../store";
import useTranslationMessages from "src/translations/useMessages";
import {SxStyle} from "src/theme/styles";
import palette from "src/theme/colors";

const sx: SxStyle = {
    m: 2,
    p: 2,
    borderRadius: 1,
    width: 180,
    height: 180,
    border: "solid thin",
    borderColor: palette.neutral[100],
    transition: "border-color .2s",
    cursor: "pointer",
};

const selectedSx: SxStyle = {
    borderColor: palette.primary[600],
};

const iconSx: SxStyle = {
    flex: 1,
    width: 60,
    height: 60,
    transition: "color .5s, width 0.2s, height 0.2s",
};

const selectedIconSx = {
    color: palette.primary[600],
    width: 120,
    height: 120,
};

export interface RoleProps {
    role: string;
    isSelected: boolean;
    onSelect: () => void;
}

export const Role = (props: RoleProps) => {
    const {role, isSelected, onSelect} = props;
    const t = useTranslationMessages();
    let Icon = PersonIcon;
    let label = "";
    switch (role) {
        case UserRoles.administrator:
            Icon = ManageAccountsIcon;
            label = t.administrator;
            break;
        case UserRoles.director:
            Icon = BusinessIcon;
            label = t.director;
            break;
        case UserRoles.secretary:
            Icon = SupervisorAccountIcon;
            label = t.secretary;
            break;
        case UserRoles.therapist:
            Icon = PsychologyAltIcon;
            label = t.therapist;
    }
    return (
        <Box sx={[sx, isSelected && selectedSx]} onClick={onSelect}>
            <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                width={1}
                height={1}>
                <Icon sx={[iconSx, isSelected && selectedIconSx]}/>
                <Typography
                    component="div"
                    fontWeight={isSelected ? 700 : undefined}
                    color={isSelected ? "primary" : "undefined"}>
                    {label}
                </Typography>
            </Stack>
        </Box>
    );
};

export default Role;
