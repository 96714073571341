import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
    ComponentPropsWithoutRef,
    forwardRef,
    ReactNode,
    useCallback,
    useState,
} from "react";
import useTranslationMessages from "src/translations/useMessages";
import { DialogRef } from "../types";
import useDialogRef from "../useDialogRef";
import { SxStyle } from "src/theme/styles";

export interface ConfirmationDialogProps {
    title?: ReactNode;
    children?: ReactNode;
    cancelBtnLabel?: string;
    confirmBtnLabel?: string;
    triggerCancelOnClose?: boolean;
    disableOutsideClick?: boolean;
    dialogActionsSx?: ComponentPropsWithoutRef<typeof DialogActions>["sx"];
    onCancel?: () => void;
    onConfirm?: () => void;
}
export const ConfirmationDialog = forwardRef<
    DialogRef,
    ConfirmationDialogProps
>((props, ref) => {
    const {
        title,
        children,
        cancelBtnLabel,
        confirmBtnLabel,
        triggerCancelOnClose,
        disableOutsideClick,
        dialogActionsSx,
        onCancel,
        onConfirm,
    } = props;
    const t = useTranslationMessages();

    const { open, hide } = useDialogRef(ref);

    const close = useCallback(() => {
        if (triggerCancelOnClose) onCancel();
        hide();
    }, [hide, onCancel, triggerCancelOnClose]);

    const cancel = useCallback(() => {
        if (onCancel) onCancel();
        hide();
    }, [hide, onCancel]);

    const confirm = useCallback(() => {
        if (onConfirm) onConfirm();
        hide();
    }, [hide, onConfirm]);
    return (
        <Dialog open={open} onClose={disableOutsideClick ? undefined : close}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{children}</DialogContentText>
            </DialogContent>

            <DialogActions sx={dialogActionsSx}>
                <Button onClick={cancel} color="inherit">
                    {cancelBtnLabel || t.cancel}
                </Button>
                <Button onClick={confirm} variant="contained" color="primary">
                    {confirmBtnLabel || t.confirm}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default ConfirmationDialog;
