import {
    FormControl,
    FormHelperText,
    FormControlLabel,
    RadioGroup,
    FormLabel,
    Radio,
    Divider,
} from "@mui/material";
import { isEqual } from "lodash";
import { ChangeEvent, memo, useCallback, useEffect } from "react";
import useTranslationMessages from "src/translations/useMessages";

export interface FormRadioGroupOption {
    key: string;
    label: string;
}

export interface FormRadioGroupProps {
    value: string;
    options: FormRadioGroupOption[];
    onChange: (value: string) => void;
    onValidationError?: (message: string) => void;
    label?: string;
    required?: boolean;
    error?: string;
}

export const FormRadioGroup = memo(
    (props: FormRadioGroupProps) => {
        const {
            value,
            error,
            onChange,
            onValidationError,
            label,
            options,
            required,
        } = props;
        const t = useTranslationMessages();
        const onRadioChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
            [onChange],
        );

        useEffect(() => {
            let msg = "";
            if (required) msg = value ? "" : t.required;
            if (error === msg) return;
            if (onValidationError) onValidationError(msg);
        }, [value, required, onValidationError, t, error]);

        return (
            <FormControl
                required={required}
                fullWidth
                error={!!error}
                variant="standard"
                margin="dense"
                sx={{ mb: 2 }}>
                <FormLabel
                    sx={{
                        color: error ? "error" : "text.primary",
                        // fontWeight: 600,
                        "&.Mui-focused": {
                            color: error ? error : "text.primary",
                        },
                    }}>
                    {label}
                </FormLabel>
                <RadioGroup value={value} onChange={onRadioChange}>
                    {options.map((o) => (
                        <FormControlLabel
                            value={o.key}
                            control={<Radio />}
                            label={o.label}
                            key={o.key}
                            sx={{ ml: 2 }}
                        />
                    ))}
                </RadioGroup>
                {error && <FormHelperText>{error}</FormHelperText>}
                <Divider />
            </FormControl>
        );
    },
    (pp, p) => isEqual(pp, p),
);

export default FormRadioGroup;
