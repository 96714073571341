import { Validator } from "./types";
import parsePhoneNumber from "libphonenumber-js";
import { LanguageStore } from "src/translations/store";

export const phoneValidator: Validator = {
    validate: (v: string) => {
        if (!v) return false;
        const phone = parsePhoneNumber(v, "UA");
        if (!phone) return false;
        return phone.isValid();
    },
    message: (t) => t.invalidPhoneNumber,
};
export const emailValidator: Validator = {
    validate: (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v),
    message: (t) => t.invalidEmailFormat,
};

export const minLength = (minLength: number): Validator => ({
    validate: (v) => !(v.length < minLength),
    message: () => {
        const formatMessage = LanguageStore.getState().formatMessage;
        return formatMessage("minLengthValidationMsg", {
            minLength,
        });
    },
});
