import { useLocation } from "react-router-dom";

export const useUserActivation = () => {
    const { hash } = useLocation();
    if (hash) {
        const isActivationLink = hash.includes("activate");

        if (isActivationLink) {
            const [, , userId, , activationCode] = hash.split("/");

            return {
                userId,
                activationCode,
            };
        }
    }
    return false;
};

export default useUserActivation;
