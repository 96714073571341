import { configureStore } from "@reduxjs/toolkit";
import QuestionnaireApi from "../redux/entities/Questionnaire/api";
import SubjectiveQuestionnaireApi from "../redux/entities/SubjectiveQuestionnaire/api";

import { api } from "./utils/createEntityApi";

const apis = [api, QuestionnaireApi, SubjectiveQuestionnaireApi];

export const store = configureStore({
    reducer: apis.reduce((acc, v) => {
        acc[v.reducerPath] = v.reducer;
        return acc;
    }, {}),
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware(),
        ...apis.map((api) => api.middleware),
    ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
