import { ForwardedRef, useCallback, useEffect, useState } from "react";
import { DialogRef } from "./types";

export const useDialogRef = (ref: ForwardedRef<DialogRef>) => {
    const [open, setOpen] = useState(false);
    const show = useCallback(() => setOpen(true), [setOpen]);
    const hide = useCallback(() => setOpen(false), [setOpen]);
    useEffect(() => {
        if (!ref) return;
        const refValue = {
            show,
            hide,
        };
        if (typeof ref === "object") {
            ref.current = refValue;
            return () => (ref.current = null);
        } else {
            ref(refValue);
            return () => ref(null);
        }
    }, [hide, ref, show]);

    return {
        open,
        setOpen,
        show,
        hide,
    };
};

export default useDialogRef;
