import { useCallback } from "react";
import { useIntl } from "react-intl";
import TranslationMessages from "./messages";

export const useTranslationMessages = () => {
    const { messages } = useIntl();
    return messages as TranslationMessages;
};

export type FormatMessageFn = (
    id: keyof TranslationMessages,
    values?: Record<string, string | number>,
) => string;

export const useFormatMessage = (): FormatMessageFn => {
    const { formatMessage: f } = useIntl();
    const formatMessage = useCallback(
        (
            id: keyof TranslationMessages,
            values: Record<string, string | number>,
        ) => {
            return f({ id }, values);
        },
        [f],
    );
    return formatMessage;
};
export default useTranslationMessages;
