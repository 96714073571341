import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { Language, LanguageStore, useLanguage } from "src/translations/store";
import useTranslationMessages from "src/translations/useMessages";
import Select, { SelectOption } from "src/components/Form/Select";

export const LanguageSelector = () => {
    const t = useTranslationMessages();
    const language = useLanguage();
    const languages: SelectOption[] = useMemo(
        () => [
            { value: "en", label: t.english },
            { value: "uk", label: t.ukrainian },
        ],
        [t],
    );

    return (
        <Box position="relative" mt={4}>
            <Stack direction="row" alignItems="flex-end" spacing={2}>
                <Typography pb={0.5}>{t.Language}:</Typography>
                <Select
                    label=""
                    value={language}
                    options={languages}
                    onChange={(lang) =>
                        LanguageStore.setValue("language", lang as Language)
                    }
                />
            </Stack>
        </Box>
    );
};
