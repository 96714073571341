import { useEffect, useRef } from "react";
import { SettingsProps, SettingsStore, useSettings } from ".";
import { deserialize, serialize } from "src/utils/serializer";
import { debounce } from "lodash";
import { EventUserAgent, GAInit } from "../tracking/ga";

const storageKey = "MEASURME_LOCAL_SETTINGS";

const debouncedSave = debounce((isInit: boolean) => {
    if (isInit) {
        try {
            const serialized = serialize(SettingsStore.getState());
            localStorage.setItem(storageKey, serialized);
        } catch {
            // suppress
        }
    }
}, 1000);
export const useSettingsEffects = () => {
    const isInit = useRef(false);
    const settings = useSettings((s) => s);
    useEffect(() => {
        let parsed: SettingsProps = {
            enableHiddenFeatures: false,
            enableHiddenSettings: false,
        };
        try {
            const str = localStorage.getItem(storageKey) || "";
            if (str) parsed = deserialize(str);
            const values: SettingsProps = {
                enableHiddenFeatures: parsed.enableHiddenFeatures || false,
                enableHiddenSettings: parsed.enableHiddenSettings || false,
            };
            SettingsStore.setState(values);
        } catch (e) {
            console.error(e);
        } finally {
            isInit.current = true;
        }
    }, []);

    useEffect(() => {
        debouncedSave(isInit.current);
    }, [settings]);

    useEffect(() => {
        GAInit();
        EventUserAgent();
    }, []);
};

export default useSettingsEffects;
