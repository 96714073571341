import createStore from "src/utils/store";
import {FormatMessageFn} from "./useMessages";
import TranslationMessages from "./messages";

export enum Languages {
    en = "en",
    uk = "uk",
}

export type Language = keyof typeof Languages;

export interface LanguageProps {
    language: Language;
    currentTranslation: TranslationMessages;
    formatMessage: FormatMessageFn;
}

export const LanguageStore = createStore<LanguageProps>({
    language: Languages.uk,
    currentTranslation: {} as any,
    formatMessage: () => "",
});

export const useLanguageStore = LanguageStore.useStore;
export const useLanguage = () => {
    const language = useLanguageStore(({language}) => language);
    return language;
};
