import { ReactElement } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useIsUserLoggedIn } from "src/components/AuthProvider/store";
import routes from ".";

export interface RequireAuthProps {
    children: ReactElement;
}
export const RequireAuth = (props: RequireAuthProps): ReactElement => {
    const location = useLocation();
    const isLoggedIn = useIsUserLoggedIn();
    if (!isLoggedIn) {
        return (
            <Navigate
                to={routes.login.path}
                state={{ from: location }}
                replace
            />
        );
    }
    return props.children;
};

export default RequireAuth;
