import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import AuthStore from "src/components/AuthProvider/store";

export const getBaseQuery = () =>
    fetchBaseQuery({
        baseUrl: process.env["REACT_APP_API_URL"],
        prepareHeaders: (headers) => {
            headers.set("accept", "application/json");
            headers.set(
                "authorization",
                `Bearer ${AuthStore.getState().session?.accessToken || ""}`,
            );
            return headers;
        },
    });

export const api = createApi({
    reducerPath: "api",
    baseQuery: getBaseQuery(),
    endpoints: () => ({}),
});

export const createEntityApi = (entity: string) => api;
