import React, {useCallback, useEffect, useRef} from "react";
import moment from "moment";
import jwtDecode, {JwtPayload} from "jwt-decode";
import AccountStore, {
    LOCAL_STORAGE_SESSION_KEY,
    LOCAL_STORAGE_USER_ID_KEY, Session,
    UserRoles,
    useSession,
    useUserId,
} from "./store";
import {LoginResponse} from "src/reduxV2/entities/Account";

export interface AuthProviderProps {
    children: React.ReactNode;
}

export interface AuthProviderState {
    isLoading: boolean;
    session: null | Session;
}

const validateSession = (session: any) => {
    if (typeof session !== "object") return false;
    const s: Session = session;
    let validated = false;
    try {
        if (
            s.accessToken &&
            s.accessTokenExpTime &&
            s.refreshToken &&
            s.userRolesWithId &&
            s.userRolesWithId
        ) {
            validated = true;
        }
    } catch (e) {
        validated = false;
    }
    return validated;
};

const validateToken = (token: string) => {
    if (!token) {
        console.error("Auth session is not valid. Token is not set.");
        return false;
    }
    let decoded: JwtPayload = null;
    try {
        decoded = jwtDecode(token);
    } catch (e) {
        console.error("Auth session is not valid. Can not parse token.");
        return false;
    }

    if (decoded.exp * 1000 < +moment().subtract(10, "minutes")) {
        console.error("Auth session is not valid. Token expired.");
        return false;
    }
    return true;
};

const refreshToken = () => {
    const session = AccountStore.getValue("session");
    if (!session) return;
    if (!session.refreshToken) return;
    //refresh token here
};

export const AuthProvider = (props: AuthProviderProps) => {
    const refreshTokenTimeoutRef = useRef<number>();
    const session = useSession();
    const userId = useUserId();
    const setRefreshTokenTimeout = useCallback(() => {
        const refreshTokenTimeout = refreshTokenTimeoutRef.current || 0;
        if (refreshTokenTimeout) window.clearTimeout(refreshTokenTimeout);
        const session = AccountStore.getValue("session");
        if (!session) return;
        if (!(session.refreshToken && session.accessTokenExpTime)) {
            return;
        }

        const timeout =
            +moment(session.accessTokenExpTime) -
            +moment().subtract(5, "minutes");
        refreshTokenTimeoutRef.current = window.setTimeout(
            refreshToken,
            timeout,
        );
    }, []);
    useEffect(() => {
        try {
            const session: Session = JSON.parse(
                window.localStorage.getItem(LOCAL_STORAGE_SESSION_KEY),
            );
            const userId = Number(
                window.localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY),
            );
            if (!session) throw new Error();
            if (
                !(
                    validateSession(session) &&
                    validateToken(session.accessToken)
                )
            ) {
                throw new Error();
            }
            const userRole = session.userRolesWithId.find(
                (r) => r.value === userId,
            );
            AccountStore.setState((prev) => ({
                ...prev,
                session,
                userId: userRole?.value || null,
                role: (userRole?.key as UserRoles) || UserRoles.none,
            }));
        } catch {
            AccountStore.getValue("logout")();
        }
    }, []);

    useEffect(() => {
        if (session) {
            AccountStore.setState((prev) => ({
                ...prev,
                isLoggedIn: true,
                isLoading: false,
            }));
            window.localStorage.setItem(
                LOCAL_STORAGE_SESSION_KEY,
                JSON.stringify(session),
            );
        }
        setRefreshTokenTimeout();
    }, [session, setRefreshTokenTimeout]);

    useEffect(() => {
        if (userId) {
            window.localStorage.setItem(
                LOCAL_STORAGE_USER_ID_KEY,
                JSON.stringify(userId),
            );
        }
    }, [userId]);
    return <>{props.children}</>;
};

export default AuthProvider;
