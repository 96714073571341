import { matchPath, useLocation } from "react-router-dom";
type QuestionnaireType = "objective" | "subjective";
export const useQuestionnaire = (): null | {
    type: QuestionnaireType;
    questionnaireId: string;
} => {
    const { hash } = useLocation();
    if (hash) {
        const url = new URL(hash.replace("#", "https://domain.com"));
        const objectiveQuestionnaireMatch = matchPath(
            "/questionnaire/:questionnaireId",
            url.pathname,
        );
        const subjectiveQuestionnaireMatch = matchPath(
            "/subjective-questionnaires/:questionnaireId",
            url.pathname,
        );

        let type: QuestionnaireType = null;
        let questionnaireId = "";

        if (objectiveQuestionnaireMatch) {
            type = "objective";
            questionnaireId =
                objectiveQuestionnaireMatch.params?.questionnaireId || "";
        } else if (subjectiveQuestionnaireMatch) {
            type = "subjective";
            questionnaireId =
                subjectiveQuestionnaireMatch.params?.questionnaireId || "";
        }

        if (type && questionnaireId)
            return {
                type,
                questionnaireId,
            };
    }
    return null;
};
