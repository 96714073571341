import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { DialogRef } from "src/components/Dialogs/types";
import useDialogRef from "src/components/Dialogs/useDialogRef";
import { SubjectiveQuestionnaire } from "src/redux/entities/SubjectiveQuestionnaire/entity";
import useTranslationMessages from "src/translations/useMessages";
import { useFullScreenDialog } from "src/components/Dialogs/useFullScreen";
import FormCheckbox from "../../../../components/Form/Checkbox";
import FormInput from "../../../../components/Form/Input";
import { SelectOption } from "../../../../components/Form/Select";
import { Close } from "@mui/icons-material";

export interface EditDialogProps {
    languages: SelectOption[];
    onLanguageChanged?: (val: SelectOption[]) => void;
}
//TODO move to constants
export const availableLanguages = [
    {
        label: "English",
        value: "en",
    },
    {
        label: "Українська",
        value: "ua",
    },
    {
        label: "Español",
        value: "es",
    },
]

export const LangDialog = forwardRef<DialogRef, EditDialogProps>(
    (props, ref) => {
        const { languages, onLanguageChanged } = props;
        const t = useTranslationMessages();
        const { open, hide } = useDialogRef(ref);
        const isFullScreen = useFullScreenDialog("sm");
        const [updatedList, setUpdatedList] = useState<Array<SelectOption>>([]);

        function changeLangList(value: boolean, lang: SelectOption) {
            if(value) {
                setUpdatedList([...updatedList, lang])
            } else {
                setUpdatedList(updatedList.filter((el) => el.value !== lang.value))
            }
        }

        useEffect(() => {
            setUpdatedList(languages)
        }, [languages]);

        return (
            <Dialog open={open} fullScreen={isFullScreen}>
                <DialogTitle>
                    {t.addQuestionnaireLanguage}
                </DialogTitle>
                <DialogContent>
                    {availableLanguages?.map((lang, idx) => (
                        <FormCheckbox value={updatedList.some((el) => el.value === lang.value)} onChange={(val) => changeLangList(val, lang)} label={lang.label}/>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" disabled={languages.length < 1} onClick={hide}>
                        {t.close}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={updatedList.length < 1}
                        onClick={() => {
                            onLanguageChanged(updatedList)
                            hide()
                        }}>
                        {t.add}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    },
);

export default LangDialog;
