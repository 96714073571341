import React, {memo, useEffect, useState} from "react";
import {usePageContext} from "src/components/Page/context";
import routes from "src/routes";
import useTranslationMessages from "src/translations/useMessages";
import {Box, Button, Stack, Theme, Toolbar, useMediaQuery} from "@mui/material";
import FormInput from "../../../components/Form/Input";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Select, {SelectOption} from "../../../components/Form/Select";
import LangDialog, {availableLanguages} from "./LangDialog";
import {useDialogControl} from "../../../components/Dialogs/useDialog";
import {SxStyle} from "../../../theme/styles";
import theme from "../../../theme";
import QuestionItem from "./QuestionItem";
import {Close} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import {useUserId} from "../../../components/AuthProvider/store";
import {checkDescriptionsFilled, checkMissingFields} from "./utils";
import {useNavigate, useParams} from "react-router-dom";
import ObjectiveQuestionnaireApi from "../../../reduxV2/entities/ObjectiveQuestionnaire";
import {ObjectiveQuestionnaireDto} from "../../../reduxV2/entities/ObjectiveQuestionnaire";
import CenteredSpinner from "../../../components/CenteredSpinner";
import {cloneDeep} from "lodash";

const toolbarSx: SxStyle = {
    marginLeft: -3,
    marginRight: -3,
    marginTop: -3,
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    zIndex: 4,
    position: "sticky",
    top: -24,
};

//TODO refactiring add Objective questionnaire (now is temporary solution)
export const AddObjective = memo(() => {
    const t = useTranslationMessages();
    const {ref: createDialogRef, show: showCreateDialog} = useDialogControl();
    const {setBreadcrumbs} = usePageContext();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const userId = useUserId();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm"),
    );
    const {id} = useParams();
    const {data, isLoading: isQuestionnaireLoading, isError} =
        ObjectiveQuestionnaireApi.useGetQuestionnaireByIdByIdQuery({id: Number(id)});

    const [createMutate, {isLoading: isAddLoading}] = ObjectiveQuestionnaireApi.useAddQuestionnaireMutation();
    const [editMutate, {isLoading: isEditLoading}] = ObjectiveQuestionnaireApi.useUpdateQuestionnaireMutation();

    const isLoading = isAddLoading || isEditLoading || isQuestionnaireLoading;

    const initialQuestionnaire = {
        id: 0,
        objectiveQuestionnaireDefinitions: [
            {
                id: 0,
                objectiveQuestionnaireId: 0,
                title: "",
                description: "",
                language: "en"
            },
        ],
        objectiveQuestionnaireQuestions: [
            {
                id: 0,
                index: 0,
                objectiveQuestionnaireId: 0,
                objectiveQuestionnaireOptions: [
                    {
                        objectiveOptionId: 0,
                        id: 0,
                        index: 0,
                        objectiveQuestionId: 0,
                        score: 0,
                        objectiveOptionDefinitions: [
                            {
                                id: 0,
                                description: "",
                                language: "en"
                            },
                        ]
                    },
                    {
                        objectiveOptionId: 0,
                        id: 0,
                        index: 0,
                        objectiveQuestionId: 0,
                        score: 0,
                        objectiveOptionDefinitions: [
                            {
                                id: 0,
                                description: "",
                                language: "en"
                            },
                        ]
                    }
                ],
                objectiveQuestionnaireQuestionDefinitions: [
                    {
                        objectiveQuestionId: 0,
                        id: 0,
                        description: "",
                        language: "en"
                    },
                ]
            }
        ],
        createdAt: new Date().toISOString(),
        therapistId: userId,
    }


    const [languages, setLanguages] = useState<Array<SelectOption>>([{label: 'English', value: 'en'}]);
    const [currentLang, setCurrentLang] = useState(languages.length > 0 && languages[0]);
    const [questionnaire, setQuestionnaire] = useState<ObjectiveQuestionnaireDto>(null);

    useEffect(() => {
        if (data) {
            let copy = cloneDeep(data)
            setQuestionnaire(copy);
            setLanguages(availableLanguages.filter((avLang) => {
                if (copy.objectiveQuestionnaireDefinitions.some((el) => el.language === avLang.value)) {
                    return avLang;
                }
            }))
        } else {
            setQuestionnaire(initialQuestionnaire)
        }
    }, [id, data])


    useEffect(() => {
        setBreadcrumbs([
            {
                name: t.addObjectiveQuestionnaire,
                link: routes.newObjectiveQuestionnaire.path,
            },
        ]);
    }, [t, setBreadcrumbs]);

    useEffect(() => {
        if (questionnaire && questionnaire.objectiveQuestionnaireDefinitions.length > languages.length) {
            const languageValues = languages.map((lang) => lang.value);

            // Filter out objectiveQuestionnaireDefinitions with language not in languageValues
            const filteredDefinitions = questionnaire.objectiveQuestionnaireDefinitions.filter(
                (definition) => languageValues.includes(definition.language)
            );

            // Filter out objectiveOptionDefinitions with language not in languageValues
            const filteredOptions = questionnaire.objectiveQuestionnaireQuestions.flatMap(
                (question) =>
                    question.objectiveQuestionnaireOptions.map((option) => ({
                        ...option,
                        objectiveOptionDefinitions: option.objectiveOptionDefinitions.filter(
                            (definition) => languageValues.includes(definition.language)
                        ),
                    }))
            );

            // Filter out objectiveQuestionnaireQuestionDefinitions with languageCulture not in languageValues
            const filteredQuestionDefinitions = questionnaire.objectiveQuestionnaireQuestions.map(
                (question) =>
                    question.objectiveQuestionnaireQuestionDefinitions.filter(
                        (definition) => languageValues.includes(definition.language)
                    )
            );

            // Create a new filtered questionnaire object
            const filteredQuestionnaire = {
                ...questionnaire,
                objectiveQuestionnaireDefinitions: filteredDefinitions,
                objectiveQuestionnaireQuestions: questionnaire.objectiveQuestionnaireQuestions.map(
                    (question, index) => ({
                        ...question,
                        objectiveQuestionnaireOptions: filteredOptions.filter(
                            (option) => option.index === index
                        ),
                        objectiveQuestionnaireQuestionDefinitions: filteredQuestionDefinitions[index],
                    })
                ),
            };

            // Update the state with the filtered questionnaire
            setQuestionnaire(filteredQuestionnaire);
        }
    }, [languages]);

    const create = () => {
        if (checkDescriptionsFilled(questionnaire)) {
            createMutate({objectiveQuestionnaireDto: questionnaire})
                .then(() => {
                    enqueueSnackbar(t.objectiveQuestionnaireCreatedSuccessfully, {
                        variant: "success",
                    });
                    navigate('/questionnaires')
                })
                .catch(() =>
                    enqueueSnackbar(t.smthWentWrongPlsContactSupport, {
                        variant: "error",
                    }),
                );
        } else {
            const [missingLang] = availableLanguages.filter((el) => el.value === checkMissingFields(questionnaire));
            setCurrentLang(missingLang)
            enqueueSnackbar(t.objectiveQuestionnaireValidationError, {
                variant: "error",
            })
        }
    }
    const edit = () => {
        if (checkDescriptionsFilled(questionnaire)) {
            editMutate({objectiveQuestionnaireDto: questionnaire})
                .then(() => {
                    enqueueSnackbar(t.objectiveQuestionnaireEditedSuccessfully, {
                        variant: "success",
                    });
                    navigate('/questionnaires')
                })
                .catch(() =>
                    enqueueSnackbar(t.smthWentWrongPlsContactSupport, {
                        variant: "error",
                    }),
                );
        } else {
            enqueueSnackbar(t.objectiveQuestionnaireValidationError, {
                variant: "error",
            })
        }
    }


    function getFormValue(arrValues: any, field: any) {
        if (arrValues.find((el) => el.language === currentLang.value)) {
            return arrValues.find((el) => el.language === currentLang.value)[field]
        } else {
            setQuestionnaire({
                ...questionnaire,
                objectiveQuestionnaireDefinitions: [...questionnaire.objectiveQuestionnaireDefinitions, {
                    id: 0,
                    objectiveQuestionnaireId: 0,
                    title: "",
                    description: "",
                    language: currentLang.value
                }]
            })
            return ''
        }
    }

    function changeFormValue(arrValues: string, field: string, value: any, mutationFunc: any) {
        const newQuestionnaire = {...questionnaire};
        newQuestionnaire[arrValues].find(el => el.language === currentLang.value)[field] = value;
        mutationFunc(newQuestionnaire);
    }


    if (isLoading) return <CenteredSpinner/>;
    return (questionnaire && (
        <Stack>
            <Toolbar color="default" sx={toolbarSx}>
                <Stack
                    width={"100%"}
                    direction={isMobile ? "column" : "row"}
                    flexDirection={isMobile ? "column-reverse" : undefined}
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={isMobile ? 0 : 4}
                    marginBottom={isMobile ? 0 : 4}>
                    <Box sx={isMobile ? {width: 1, mt: 4} : {flex: 1}}>
                        <FormInput
                            required
                            error={!getFormValue(questionnaire.objectiveQuestionnaireDefinitions, 'title') && t.required}
                            label={t.questionnaireName}
                            value={getFormValue(questionnaire.objectiveQuestionnaireDefinitions, 'title')}
                            onChange={(val) => changeFormValue('objectiveQuestionnaireDefinitions', 'title', val, setQuestionnaire)}
                        />
                        <FormInput
                            required
                            label={t.questionnaireDescription}
                            value={getFormValue(questionnaire.objectiveQuestionnaireDefinitions, 'description')}
                            error={!getFormValue(questionnaire.objectiveQuestionnaireDefinitions, 'description') && t.required}
                            onChange={(val) => changeFormValue('objectiveQuestionnaireDefinitions', 'description', val, setQuestionnaire)}
                        />
                    </Box>
                    <Stack
                        direction="row"
                        justifyContent={isMobile ? "space-between" : undefined}
                        spacing={4}
                        width={450}>
                        <Select
                            label={t.questionnaireLanguage}
                            value={currentLang.value}
                            options={languages}
                            onChange={(val) => setCurrentLang({label: val, value: val})}/>
                        <Button
                            fullWidth
                            startIcon={<AddCircleOutlineIcon/>}
                            variant="contained"
                            onClick={showCreateDialog}>
                            {t.addLanguage}
                        </Button>
                    </Stack>
                </Stack>
            </Toolbar>
            {questionnaire.objectiveQuestionnaireQuestions.map((question, idx) => (
                <Box sx={{position: 'relative', marginTop: 2}}>
                    <QuestionItem
                        key={idx}
                        question={question}
                        idx={idx}
                        languages={languages}
                        currentLang={currentLang}
                        onQuestionUpdate={(updatedQuestion) => {
                            setQuestionnaire((prevQuestionnaire) => {
                                const updatedQuestions = [...prevQuestionnaire.objectiveQuestionnaireQuestions];
                                updatedQuestions[idx] = updatedQuestion;

                                return {
                                    ...prevQuestionnaire,
                                    objectiveQuestionnaireQuestions: updatedQuestions,
                                };
                            });
                        }}
                    />
                    {questionnaire.objectiveQuestionnaireQuestions.length > 1 &&
                        <Close
                            sx={{position: 'absolute', top: 0, right: 0, cursor: 'pointer'}}
                            onClick={() => {
                                setQuestionnaire((prevQuestionnaire) => ({
                                    ...prevQuestionnaire,
                                    objectiveQuestionnaireQuestions: prevQuestionnaire.objectiveQuestionnaireQuestions.filter(
                                        (question, i) => i !== idx
                                    ),
                                }));
                            }}/>
                    }
                </Box>
            ))}
            <Box>
                <Button
                    startIcon={<AddCircleOutlineIcon/>}
                    variant="contained"
                    onClick={() => {
                        const newQuestion = {
                            id: 0,
                            index: 0,
                            objectiveQuestionnaireId: 0,
                            objectiveQuestionnaireOptions: [
                                {
                                    objectiveOptionId: 0,
                                    id: 0,
                                    index: 0,
                                    objectiveQuestionId: 0,
                                    score: 0,
                                    objectiveOptionDefinitions: [
                                        {
                                            id: 0,
                                            description: "",
                                            language: currentLang.value
                                        }
                                    ]
                                },
                                {
                                    objectiveOptionId: 0,
                                    id: 0,
                                    index: 0,
                                    objectiveQuestionId: 0,
                                    score: 0,
                                    objectiveOptionDefinitions: [
                                        {
                                            id: 0,
                                            description: "",
                                            language: currentLang.value
                                        }
                                    ]
                                }
                            ],
                            objectiveQuestionnaireQuestionDefinitions: [
                                {
                                    objectiveQuestionId: 0,
                                    id: 0,
                                    description: "",
                                    language: currentLang.value
                                }
                            ]
                        }
                        setQuestionnaire({
                            ...questionnaire,
                            objectiveQuestionnaireQuestions: [...questionnaire.objectiveQuestionnaireQuestions, newQuestion]
                        })
                    }}>
                    {t.addQuestion}
                </Button>
            </Box>
            <Button
                sx={{position: 'fixed', bottom: 20, right: 20, padding: 2}}
                variant="contained"
                onClick={() => id ? edit() : create()}>
                {id ? t.edit : t.create}
            </Button>
            <LangDialog
                ref={createDialogRef}
                languages={languages}
                onLanguageChanged={(updatedList) => {
                    if (updatedList.length > 0) {
                        setLanguages(updatedList)
                        setCurrentLang(updatedList[updatedList.length - 1])
                    }
                }}
            />
        </Stack>
    ));
});

export default AddObjective;
