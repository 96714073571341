import {memo, MouseEvent, useCallback, useEffect, useState} from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {Box, IconButton, Link, Menu, MenuItem, Theme, Typography, useMediaQuery,} from "@mui/material";
import useTranslationMessages from "src/translations/useMessages";
import routes from "src/routes";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog";
import {useDialogControl} from "../Dialogs/useDialog";
import {UserRoles, useSession, useUserRole} from "../AuthProvider/store";
import jwtDecode from "jwt-decode";
// import UserApi from "src/redux/entities/User/api";
export const User = memo(() => {
    const t = useTranslationMessages();
    // const { data, isLoading } = UserApi.useGetUserNameQuery();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm"),
    );
    const role = useUserRole();
    const session = useSession();
    const [email, setEmail] = useState<string>('')
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const onClose = useCallback(() => setAnchor(null), []);
    const onOpen = useCallback((event: MouseEvent<HTMLElement>) => {
        setAnchor(event.currentTarget);
    }, []);
    const {ref: logOutRef, show: showLogoutDialog} = useDialogControl();
    const logout = useCallback(() => {
        setAnchor(null);
        showLogoutDialog();
    }, [setAnchor, showLogoutDialog]);

    const onLogoutConfirmed = useCallback(() => {
        localStorage.clear();
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }, []);
    useEffect(() => {
        if (session.accessToken) {
            const decoded: any = jwtDecode(session.accessToken);
            setEmail(decoded.email)
        }
    }, [session])

    const getUserRole = (role: UserRoles) => {
        switch (role) {
            case UserRoles.administrator:
                return t.administrator;
            case UserRoles.director:
                return t.director;
            case UserRoles.secretary:
                return t.secretary;
            case UserRoles.supervisor:
                return t.supervisor;
            case UserRoles.therapist:
                return t.therapist;
            default:
                return '';
        }
    }
    return (
        <Box sx={{flexGrow: 0}}>
            <IconButton sx={{color: "black", mr: -2}} onClick={onOpen} style={{background: 'transparent'}}>
                <div>
                    {!isMobile && <Typography variant={'h6'}>{email}</Typography>}
                    {!isMobile && role !== UserRoles.none &&
                        <Typography sx={{textAlign: 'right', color: 'rgb(100, 116, 139)'}}
                                    variant={'subtitle2'}>{getUserRole(role)}</Typography>}
                </div>
                <AccountCircleIcon sx={{width: 40, height: 40, marginLeft: 1}}/>
            </IconButton>
            <Menu
                sx={{mt: "45px"}}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchor)}
                onClose={onClose}>
                {/* {data?.name ? (
                    <Box>
                        <Typography component="div" px={2} py={1}>
                            {data.name}
                        </Typography>
                        <Divider />
                    </Box>
                ) : null} */}
                <MenuItem
                    component={Link}
                    href={routes.settings.path}
                    onClick={onClose}>
                    {t.settings}
                </MenuItem>
                <MenuItem onClick={logout}>{t.logOut}</MenuItem>
            </Menu>
            <ConfirmationDialog ref={logOutRef} onConfirm={onLogoutConfirmed}>
                {t.logoutMessage}
            </ConfirmationDialog>
        </Box>
    );
});

export default User;
