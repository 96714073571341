import { forwardRef } from "react";
import { Link as DomLink, LinkProps } from "react-router-dom";

export const LinkBehavior = forwardRef<
    any,
    Omit<LinkProps, "to"> & { href: LinkProps["to"] }
>((props, ref) => {
    const { href, ...other } = props;
    return <DomLink ref={ref} to={href} {...other} />;
});

export default LinkBehavior;
