import {
    FormControl,
    InputLabel,
    Input as MuiInput,
    FormHelperText,
} from "@mui/material";
import {isEqual} from "lodash";
import {
    ChangeEvent,
    ComponentProps,
    memo,
    useCallback,
    useEffect,
} from "react";
import useTranslationMessages from "src/translations/useMessages";
import {Validator} from "src/utils/validators/types";

type MuiInputProps = ComponentProps<typeof MuiInput>;

export interface FormInputProps {
    value: string;
    onChange: (value: string) => void;
    onValidationError?: (message: string) => void;
    label?: string;
    type?: MuiInputProps["type"];
    inputProps?: MuiInputProps["inputProps"];
    required?: boolean;
    error?: string;
    validators?: Validator[];
    multiline?: boolean;
    rows?: number;
    autocomplete?: string;
    placeholder?: string;
    disabled?: boolean;
}

export const FormInput = memo(
    (props: FormInputProps) => {
        const {
            value,
            error,
            onChange,
            onValidationError,
            label,
            type,
            inputProps = {},
            required,
            disabled,
            validators,
            multiline,
            placeholder,
            rows,
            autocomplete = 'on'
        } = props;
        const t = useTranslationMessages();
        const mergedInputProps = {maxLength: 200, ...inputProps};
        const onInputChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                const v = e.target.value;
                onChange(v);
            },
            [onChange],
        );

        useEffect(() => {
            let msg = "";
            let validate = false;
            if (required || (value !== "" && validators)) validate = true;
            if (validate) {
                if (validators?.length) {
                    for (let validator of validators) {
                        if (!validator.validate(value)) {
                            msg = validator.message(t);
                            break;
                        }
                    }
                }
                if (!msg && required) msg = value ? "" : t.required;
            }
            if (error === msg) return;
            if (onValidationError) onValidationError(msg);
        }, [value, validators, required, onValidationError, t, error]);

        return (
            <FormControl
                required={required}
                fullWidth
                error={!!error}
                variant="standard"
                margin="dense">
                <InputLabel>{label}</InputLabel>
                <MuiInput
                    disabled={disabled}
                    autoComplete={autocomplete}
                    type={type}
                    inputProps={mergedInputProps}
                    placeholder={placeholder}
                    value={value}
                    onChange={onInputChange}
                    multiline={multiline}
                    rows={rows}
                />
                {error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        );
    },
    (pp, p) => isEqual(pp, p),
);

export default FormInput;
