import React, {useState} from "react";
import {Box, Button, Divider, Stack, Theme, useMediaQuery} from "@mui/material";
import FormInput from "../../../../components/Form/Input";
import FormCheckbox from "../../../../components/Form/Checkbox";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useTranslationMessages from "../../../../translations/useMessages";
import Answers from "./Answers";
import {ObjectiveQuestion} from "../../../../redux/entities/ObjectiveQuestionnaire/entity";
import {ObjectiveQuestionDto} from "../../../../reduxV2/entities/ObjectiveQuestionnaire/codegen.api";

interface QuestionItemProps {
    idx: number;
    question: ObjectiveQuestionDto;
    currentLang: any;
    onQuestionUpdate: (val: any) => void;
    languages: any;
}

const QuestionItem = (props: QuestionItemProps) => {
    const {idx, question, currentLang, onQuestionUpdate, languages} = props;
    const t = useTranslationMessages();
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("sm"),
    );
    const [questionError, setQuestionError] = useState<string>('');

    function getFormValue(arrValues: any, field: any) {
        if (arrValues.find((el) => el.language === currentLang.value)) {
            return arrValues.find((el) => el.language === currentLang.value)[field]
        } else {
            onQuestionUpdate({
                ...question,
                objectiveQuestionnaireQuestionDefinitions: [...question.objectiveQuestionnaireQuestionDefinitions, {
                    objectiveQuestionId: 0,
                    id: 0,
                    description: "",
                    language: currentLang.value
                }]
            })
            return ''
        }
    }

    function changeFormValue(arrValues: string, field: string, value: any, mutationFunc: any) {
        const newQuestion = {...question};
        newQuestion[arrValues].find(el => el.language === currentLang.value)[field] = value;
        mutationFunc(newQuestion);
    }

    return (
        <>
            <span>{idx + 1}.</span>
            <Stack
                direction={isMobile ? "column" : "row"}
                flexDirection={isMobile ? "column-reverse" : undefined}
                justifyContent="space-between"
                alignItems="center"
                spacing={isMobile ? 0 : 4}
                marginBottom={isMobile ? 0 : 4}>
                <Box sx={isMobile ? {width: 1, mt: 4} : {flex: 1}}>
                    <FormInput
                        required
                        label={t.question}
                        error={questionError}
                        onValidationError={(e) => setQuestionError(e)}
                        value={getFormValue(question.objectiveQuestionnaireQuestionDefinitions, 'description')}
                        onChange={(val) => changeFormValue('objectiveQuestionnaireQuestionDefinitions', 'description', val, onQuestionUpdate)}
                    />
                </Box>
            </Stack>
            <Answers
                answers={question.objectiveQuestionnaireOptions}
                currentLang={currentLang}
                languages={languages}
                onAnswersUpdated={(updatedAnswers) => onQuestionUpdate({
                    ...question,
                    objectiveQuestionnaireOptions: updatedAnswers
                })}
            />
            <Divider sx={{marginBottom: 7}} color={'red'} variant="fullWidth"/>
        </>
    );
};

export default QuestionItem;